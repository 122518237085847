import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import FirebaseClient from "../../api/firebase/firebase_client";
import { MintNFTForm } from "../../components/tokens/MintNFTForm";
import { RequestedTokensList } from "../../components/tokens/RequestedTokensList";
import { unpackSnapshot } from "../../utils/unpack_snapshot";

export const MintNFTPage = () => {
  // Get the Selected NFT Contract's address
  const { data: selectedNFTContract } = useQuery(
    ["defaultContracts", { id: "nft" }],
    () => {
      return (
        FirebaseClient.db
          .collection("contracts")
          .doc("nft")
          // .doc("nfts")
          .get()
          .then((snapshot) => {
            return unpackSnapshot({ snapshot });
          })
      );
    }
  );

  return (
    <div className="">
      <div
        className="relative -mt-16 h-60 w-full bg-center bg-cover"
        style={{ backgroundImage: "url(/bg-mint.png)" }}
      >
        <div className="absolute h-full w-full bg-black opacity-10" />
        <h1 className="relative z-10 pt-20 flex space-x-5 mx-auto max-w-7xl my-auto text-white">
          <span className="text-6xl font-extrabold">Mint</span>
          <img className="h-20" src="/blocks.svg" alt="blocks icon" />
        </h1>
      </div>

      <div className="mt-10" />

      <div className="p-5 lg:p-0 mx-auto max-w-7xl">
        <header className="space-y-2">
          <h1 className="text-5xl font-bold" style={{ lineHeight: "120%" }}>
            Mint a new NFT
          </h1>
        </header>

        <div className="mt-5" />

        <div className="md:flex md:space-x-5 space-y-5 md:space-y-0">
          <p className="text-xl leading-8">Contract type:</p>
          <div className="relative flex space-x-5">
            <Link to="/contracts/nft">
              <img className="h-14 w-14" src="/smart-contract.svg" alt="" />
            </Link>
            <div>
              <Link
                to="/contracts/nft"
                className="block text-xl md:text-2xl text-indigo-600 font-bold"
              >
                VertuaLabsPermissiveNFT (VLNFT)
              </Link>
              <a
                className="inline-flex md:text-lg opacity-60 underline"
                href={`https://ropsten.etherscan.io/address/${selectedNFTContract?.contract.address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View contract on Etherscan {selectedNFTContract?.address}
              </a>
            </div>
          </div>
        </div>

        <div className="mt-10" />

        <MintNFTForm contractId="nft" />

        <div className="mt-10" />
        <RequestedTokensList />

        <div className="pb-24" />
      </div>
    </div>
  );
};
