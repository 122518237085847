import { Helmet } from "react-helmet";
import { useMutation } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { Spinner } from "../../components/network_state/Spinner";

export const AdminPage = () => {
  const mutation = useMutation(() => {
    // forceAlogliaReindex;
    return FirebaseClient.functions
      .httpsCallable("forceAlogliaReindex")()
      .then((response) => {
        console.log(response);
      });
  });
  return (
    <main className="p-5 lg:p-0 mx-auto max-w-5xl">
      <Helmet>
        <title>Admin Page</title>
      </Helmet>
      <header>
        <h1 className="text-3xl font-bold">Admin Page</h1>
      </header>

      <div className="pt-10" />
      <section className="p-5 border shadow rounded">
        <h2 className="text-2xl font-bold">Search</h2>
        <div className="pt-5" />
        <button
          className="px-8 py-2 bg-blue-200 text-sm rounded"
          onClick={() => mutation.mutate()}
        >
          {mutation.isLoading && <Spinner />}
          Force Re-indexing
        </button>
      </section>
    </main>
  );
};
