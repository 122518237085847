import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import FirebaseClient from "../../api/firebase/firebase_client";
import { useMetamask } from "../../contexts/metamask.context";
import { unpackSnapshotArray } from "../../utils/unpack_snapshot_array";

export const RequestedTokensList = () => {
  const [nftRequests, setNFTRequests] = useState<any[]>([]);

  const { publicAddress } = useMetamask();
  // console.log(publicAddress);

  useEffect(() => {
    if (!publicAddress) {
      return;
    }
    const unsubscribe = FirebaseClient.db
      .collection("tokens")
      .where("requestedByAddress", "==", publicAddress)
      .where("state", "!=", "minted")
      .onSnapshot({
        next: (snapshots) => {
          let data = unpackSnapshotArray({ snapshots });
          console.log("Next", data);
          data = data.sort((a, b) => b.createdAt - a.createdAt);
          setNFTRequests(data);
        },
        error: (err) => {
          console.error("Error: ", err);
        },
      });

    return () => unsubscribe();
  }, [publicAddress]);

  return (
    <div className="relative p-8 border rounded-lg shadow-2xl overflow-hidden">
      <header>
        <h2 className="text-xl font-medium">Requested NFTs and their States</h2>
        <p className="text-sm">
          Below are shown tokens that are currently minting and that we known
          about.
        </p>
      </header>
      <ul className="mt-5 space-y-2">
        {(nftRequests as any)?.map((request: any) => {
          return (
            <div key={request.id} className="p-5 border border-1 shadow">
              <Link to={`/tokens/${request.id}`}>
                <div>name: {request.metadata?.name}</div>
                <div>state: {request.state}</div>
              </Link>
            </div>
          );
        })}
      </ul>
      <div className="my-10" />
    </div>
  );
};
