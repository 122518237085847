import { useContext } from "react";
import { Helmet } from "react-helmet";

import { useContractQuery } from "../../api/metamask/use_contract_query";
import { AdminControlPanel } from "../../components/contracts/AdminControlPanel";
import { ContractHeader } from "../../components/contracts/ContractHeader";
import { ContractInterfacePanel } from "../../components/contracts/ContractInterface";
import { MintNFTForm } from "../../components/tokens/MintNFTForm";
import { TransferFromForm } from "../../components/tokens/TransferFromForm";
import { useMetamask } from "../../contexts/metamask.context";
import { ContractContext } from "./ContractPage";

/**
 *
 *
 */
export const NFTContract = () => {
  const data = useContext(ContractContext);
  // console.log("Data", data);
  const { publicAddress } = useMetamask();

  const { data: contractName } = useContractQuery({
    address: data?.contract.address,
    args: [],
    contractId: data?.id,
    functionSelector: "name()",
  });
  const { data: contractSymbol } = useContractQuery({
    address: data?.contract.address,
    args: [],
    contractId: data?.id,
    functionSelector: "symbol()",
  });

  // TODO: need to query blockchain to identify contract owner
  // const { data: contractOwner } = useOwner(data?.contract.address);

  const header = `${contractName || data?.metadata?.name} (${
    data?.metadata?.symbol || contractSymbol
  })`;

  const subheader = "ERC720 (NFT) Contract";

  return (
    <div className="relative p-5 lg:p-0 mx-auto max-w-7xl">
      <Helmet>
        <title>{header} NFT Contract</title>
      </Helmet>
      <main>
        <ContractHeader contract={data} header={header} subheader={subheader} />

        {/* If being Auctioned, display AuctionPanel */}
        {/* If not being Auctioned and owner if viewing, display Actions Panel */}

        <div className="py-10" />
        {/* <NFTAdminControlPanel /> */}
        <AdminControlPanel />

        <div className="py-10" />
        <ContractInterfacePanel />
        {/* <NFTPublicInterfacePanel /> */}

        <div className="py-10" />
        <MintNFTForm contractId={data?.id} />

        <div className="py-10" />
        {publicAddress && (
          <TransferFromForm
            publicAddress={publicAddress}
            tokenContract={{ address: data?.contract.address }}
          />
        )}
      </main>
      <div className="py-72" />
    </div>
  );
};
