import { ethers } from "ethers";

const abi = [
  "function cancelAuction(uint256)",
  "event AuctionCancelled(uint256)",
];

export const cancelAuction = async ({
  address,
  id,
}: {
  address: string | undefined;
  id: string | undefined;
}) => {
  if (!address || !id) {
    console.log("opps");

    return;
  }
  if (typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(address, abi, signer);
    console.log("Found contract", address, contract);

    try {
      const transaction = await contract.cancelAuction(id);
      console.log("transaction", transaction);
      const result = await transaction.wait();
      console.log("transaction completed", result);
      return result;
    } catch (error) {
      console.error("startPreAuction Error:", error);
    }
  } else {
    console.log("couldn't find window.ethereum");
  }
};
