import { XIcon } from "@heroicons/react/solid";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useMutation } from "react-query";

import { safeTransferFrom } from "../../api/metamask/nfts/safe_transfer_from";
import { Spinner } from "../network_state/Spinner";

export const TransferFromForm = ({
  publicAddress,
  auctionContract,
  tokenContract,
}: {
  publicAddress: string;
  auctionContract?: {
    address: string;
    id?: string;
  };
  tokenContract?: {
    address: string;
    id?: string;
  };
}) => {
  // const publicAddress = useMetamaskAccount();
  // console.log("Public Address", publicAddress);

  const mutation = useMutation(
    async ({ from, to, id }: { from: string; to: string; id: string }) => {
      // Call transferFrom directly on the contract
      if (!tokenContract) return;
      try {
        await safeTransferFrom({
          tokenAddress: tokenContract.address,
          from,
          to,
          id,
        });
      } catch (error) {
        console.error(error);
      }
      // Need to update the firestore database
    }
  );

  return (
    <div className="relative p-5 bg-white border rounded-lg shadow-2xl">
      <header className="">
        <h1 className="text-2xl font-bold" style={{ lineHeight: "120%" }}>
          Transfer Token
        </h1>
      </header>
      <Formik
        initialValues={{
          from: publicAddress,
          to: auctionContract?.address || "",
          id: tokenContract?.id || "",
        }}
        onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
          mutation.mutate(values);
        }}
      >
        <Form className="space-y-5">
          {/* FROM */}
          <div className="mt-3">
            <label htmlFor="from" className="field-label">
              From Address
            </label>
            <div className="mt-1">
              <Field
                type="text"
                name="from"
                id="from"
                className="field-text"
                placeholder="From your public address"
              />
            </div>
          </div>

          {/* TO */}
          <div className="mt-3">
            <label htmlFor="to" className="field-label">
              To Address
            </label>
            <div className="mt-1">
              <Field
                type="text"
                name="to"
                id="to"
                className="field-text"
                placeholder="To destination address"
              />
            </div>
          </div>

          {/* TOKEN ID*/}
          <div className="mt-3">
            <label htmlFor="id" className="field-label">
              Token Id
            </label>
            <div className="mt-1">
              <Field
                type="text"
                name="id"
                id="id"
                className="field-text"
                placeholder="Token id"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-5 mt-2 rounded-full">
            <button type="reset" className="btn-reset">
              <XIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              <span>Cancel</span>
            </button>
            <button className="btn-submit" type="submit">
              {mutation.isLoading && <Spinner />}
              Transfer token
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
