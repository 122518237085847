import { ExternalLinkIcon } from "@heroicons/react/outline";
import { ethers } from "ethers";
import { useQuery } from "react-query";

import { useRecentBids } from "../../api/firebase/auctions/use_recent_bids";
import { Auction } from "../../types/firestore";

/**
 * Auction Recent Bids Panel
 *
 */
export const RecentBidsPanel = ({
  auction,
}: {
  auction: Auction | undefined;
}) => {
  const { bids } = useRecentBids({ auction, limit: 5 });
  // TODO: need to query the contract and lookup recent events filtered onHighestBidIncreased

  const { data: conversion } = useQuery(["eth2usd"], async () =>
    fetch("https://cex.io/api/last_price/ETH/USD").then(async (response) => {
      const json = await response.json();
      // console.log(json);
      return json?.lprice;
    })
  );

  return (
    <div className="p-12 bg-white rounded-xl border shadow overflow-hidden">
      <header className="flex justify-between">
        <h1 className="text-3xl font-bold">Recent bids</h1>
        <p className="text-sm">See All</p>
      </header>
      {/*  */}
      <div className="py-4" />
      {/* Show Five Recent Bids */}
      <ul className="space-y-4">
        {bids?.map((bid) => {
          const ethBid = ethers.utils.formatEther(
            ethers.BigNumber.from(bid?.ethBid || "0")
          );

          const byAddress = `${bid.byAddress.slice(0, 8)}...`;
          const createdAt = new Date(bid?.createdAt).toLocaleString();

          const usdBid = (parseFloat(ethBid) * (conversion || 0)).toFixed(2);
          return (
            <div
              key={bid.id}
              className="p-2 w-full space-y-2 rounded-xl shadow hover:shadow-2xl"
            >
              <div className="flex items-center space-x-4">
                <img
                  className="h-8 w-8 object-cover rounded-full overflow-hidden"
                  src={bid?.photoURL || "/penrose.svg"}
                  alt=""
                />
                <p className="ml-8 flex-grow text-sm font-bold">
                  "{bid.comment}"
                </p>

                <dl>
                  <dd className="sr-only">ETH Bid</dd>
                  <dt className="flex items-center space-x-2 truncate font-syne-mono">
                    <img
                      className="h-4"
                      src="/ethereum-icon.svg"
                      alt="ethereum icon"
                    />
                    <span>{ethBid}</span>
                  </dt>
                  <dd className="sr-only">USD Bid</dd>
                  <dt className="font-syne-mono">$ {usdBid}</dt>
                </dl>
                <a
                  className="h-5 w-5 text-gray-500"
                  href={`https://ropsten.etherscan.io/tx/${bid?.transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ExternalLinkIcon />
                </a>
              </div>
              {/*  */}
              <div className="flex items-center space-x-6 text-xs text-gray-700">
                <h3 className="text-sm">{createdAt}</h3>

                <p className="hidden sm:block text-sm">Placed by {byAddress}</p>
                <p className="hidden sm:block ">state: "{bid?.state}"</p>
              </div>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
