import { Helmet } from "react-helmet";
import { useQuery } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { AuctionCard, ContractCard } from "../../components/BasicCards";
import { unpackSnapshotArray } from "../../utils/unpack_snapshot_array";

export const Market = () => {
  const { data } = useQuery(
    ["auctions"],
    async () => {
      return FirebaseClient.db
        .collection("auctions")
        .where("state", "==", "running")
        .get()
        .then((snapshots) => {
          console.log("found auctions", snapshots.size);

          return unpackSnapshotArray({ snapshots });
        });
    }
    // { enabled: !!publicAddress }
  );

  const { data: contracts } = useQuery(
    ["contracts"],
    async () => {
      return FirebaseClient.db
        .collection("contracts")
        .get()
        .then((snapshots) => {
          console.log("found contracts", snapshots.size);

          return unpackSnapshotArray({ snapshots });
        });
    }
    // { enabled: !!publicAddress }
  );

  const defaultContracts = contracts?.filter(
    (contract) =>
      contract.id === "auction" ||
      contract.id === "erc20-fixed" ||
      contract.id === "erc20-minter" ||
      contract.id === "nft"
  );

  return (
    <div className="">
      <Helmet>
        <title>Markets</title>
      </Helmet>

      {/* LIVE AUCTIONS */}
      <div className="" />
      <main className="p-5 lg:p-0 mx-auto max-w-5xl">
        <section>
          <h1 className="text-3xl font-bold leading-loose">Contracts</h1>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center gap-8">
            {(defaultContracts as any)?.slice(0, 8).map((contract: any) => {
              return <ContractCard key={contract.id} contract={contract} />;
            })}
          </ul>
        </section>

        <div className="py-10" />

        <section>
          <header className="text-3xl font-bold leading-loose">Auctions</header>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center gap-8">
            {data?.map((auction: any) => {
              return <AuctionCard key={auction.id} auction={auction} />;
            })}
          </ul>
        </section>
      </main>

      <div className="py-10" />
    </div>
  );
};

export default Market;
