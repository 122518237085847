import { useMetamask } from "../../contexts/metamask.context";
import { useFirebaseUser } from "../../hooks/firebase_user.context";

export const AccountPage = () => {
  const { publicAddress } = useMetamask();
  const user = useFirebaseUser();
  return (
    <div className="h-screen mx-auto max-w-5xl">
      <header>
        <h1 className="text-2xl font-bold">Your Account</h1>
      </header>
      <div className="mt-10" />
      <dl>
        <dt className="text-gray-700">Wallet Address</dt>
        <dd>{publicAddress}</dd>

        <dt className="mt-5 text-gray-700">Firebase Auth Id</dt>
        <dd>{user?.uid}</dd>
      </dl>
    </div>
  );
};
