import MetaMaskOnboarding from "@metamask/onboarding";
import { ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "react-query";
import { Redirect } from "react-router";

import { signInWithMetamask } from "../../api/firebase/auth/sign_in_with_metamask";
import FirebaseClient from "../../api/firebase/firebase_client";
import { useConnect } from "../../api/metamask/use_connect";

const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect";
const CONNECTED_TEXT = "Connected";

export const ConnectPage = () => {
  const connect = useConnect();

  // if (currentUser) {
  //   return <Redirect to="/" />;
  // }

  return (
    <div className="pb-36 min-h-screen">
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <div className="text-left sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
          Connect your wallet
        </h2>
        <div className="mt-5" />
        <p>In order to use this site, you need to connect a Metamask wallet.</p>
        <p>
          If you have never used Metamask and want an introduction, please see
          the video below.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex flex-col justify-center bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="mx-auto mt4">
            <button
              className="flex content-center bg-white px-2 rounded-lg border w-full text-center"
              onClick={() => connect.mutate()}
            >
              <img
                className="flex-shrink  p-2 h-10 w-10"
                src="/metamask-fox.svg"
                alt="Metamask Fox Logo"
              />
              <span className="flex-grow p-2">Sign in with Metamask</span>
            </button>
          </div>
        </div>
      </div>

      <div className="mt-10" />
      <iframe
        className="block mx-auto"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Af_lQ1zUnoM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default ConnectPage;
