import { createContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import FirebaseClient from "../../api/firebase/firebase_client";
import { unpackSnapshot } from "../../utils/unpack_snapshot";
import { AuctionContract } from "./AuctionContractPage";
import { ERC20FixedContract } from "./ERC20FixedContractPage";
import { ERC20MinterContract } from "./ERC20MinterContractPage";
import { NFTContract } from "./NFTContractPage";

export const ContractContext = createContext<any>({});

export const ContractPage = () => {
  const { id } = useParams<any>();

  const { data, isLoading } = useQuery(
    ["contracts", { id }],
    async () => {
      return FirebaseClient.db
        .collection("/contracts")
        .doc(id)
        .get()
        .then((snapshot) => {
          return unpackSnapshot({ snapshot });
        });
    },
    { enabled: !!id }
  );

  //TODO: shim. idea is to send a request to the server to check to see if the selected contract has been entered into the blockchain
  useEffect(() => {
    if (!data) return;
    // if the contract has already been found to be deployed, no need to hit our servers
    if (data?.state === "deployed") return;
    FirebaseClient.functions
      .httpsCallable("checkContractDeployed")({ id })
      .then(({ data }) => {
        console.log("Check Contract Deployed Response", data);

        // depending on result we'll update the queryCache
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id, data]);

  if (data?.contractType?.toLowerCase() === "nft") {
    return (
      <ContractContext.Provider value={data}>
        <NFTContract />
      </ContractContext.Provider>
    );
  }

  if (data?.contractType?.toLowerCase() === "auction") {
    return (
      <ContractContext.Provider value={data}>
        <AuctionContract />
      </ContractContext.Provider>
    );
  }

  if (data?.contractType?.toLowerCase() === "erc20-fixed") {
    return (
      <ContractContext.Provider value={data}>
        <ERC20FixedContract />
      </ContractContext.Provider>
    );
  }

  if (data?.contractType?.toLowerCase() === "erc20-minter") {
    return (
      <ContractContext.Provider value={data}>
        <ERC20MinterContract />
      </ContractContext.Provider>
    );
  }

  if (isLoading) {
    <div className="mx-auto max-w-5xl text-center">
      <span>loading</span>
    </div>;
  }

  return (
    <div className="mx-auto max-w-5xl text-center">
      <span>Error: contract not found</span>
    </div>
  );
};
