import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import FirebaseClient from "../../api/firebase/firebase_client";
import { CreateAuctionForm } from "../../components/auctions/CreateAuctionForm";
import { unpackSnapshot } from "../../utils/unpack_snapshot";

export const CreateAuctionPage = () => {
  const { data: selectedAuctionContract } = useQuery(
    // TODO: update the key with an Id once I have multiple auction contracts
    ["defaultContracts", { id: "auction" }],
    async () => {
      return FirebaseClient.db
        .collection("contracts")
        .doc("auction")
        .get()
        .then((snapshot) => {
          return unpackSnapshot({ snapshot });
        });
    }
  );
  return (
    <div className="relative">
      <div
        className="relative -mt-16 h-60 w-full bg-center bg-cover"
        style={{ backgroundImage: "url(/bg-create.png)" }}
      >
        <h1 className="pt-20 flex space-x-5 mx-auto max-w-7xl my-auto text-white">
          <span className="text-6xl font-extrabold">Auction</span>
          <img className="h-20 w-20" src="/auction.svg" alt="auction icon" />
        </h1>
      </div>

      <div className="mt-10" />

      <div className="p-5 lg:p-0 mx-auto max-w-7xl">
        <header className="space-y-2">
          <h1 className="text-5xl font-bold" style={{ lineHeight: "120%" }}>
            Create a new Auction
          </h1>
        </header>

        <div className="mt-5" />

        <div className="md:flex md:space-x-5 space-y-5 md:space-y-0">
          <p className="text-xl leading-8">Contract type:</p>
          <div className="relative flex space-x-5">
            <Link to="/contracts/auction">
              <img className="h-14 w-14" src="/smart-contract.svg" alt="" />
            </Link>
            <div>
              <Link
                to="/contracts/auction"
                className="block text-xl md:text-2xl text-indigo-600 font-bold"
              >
                VertuaLabsPermissiveAuction
              </Link>
              <a
                className="inline-flex md:text-lg opacity-60 underline"
                href={`https://ropsten.etherscan.io/address/${selectedAuctionContract?.contract.address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View contract on Etherscan {selectedAuctionContract?.address}
              </a>
            </div>
          </div>
        </div>

        <div className="mt-10" />

        <CreateAuctionForm />

        <div className="pb-36" />
      </div>
    </div>
  );
};
