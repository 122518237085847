import { Switch } from "@headlessui/react";
import { ethers } from "ethers";
import { useContext } from "react";
import { useMutation, UseMutationResult } from "react-query";

import { useContractQuery } from "../../api/metamask/use_contract_query";
import { queryClient } from "../../api/react_query/react_query";
import { ContractContext } from "../../pages/contracts/ContractPage";
import { Spinner } from "../network_state/Spinner";

const ABI = ["function pause()", "function unpause()"];

export const AdminControlPanel = () => {
  const data = useContext(ContractContext);
  // console.log("data", data);

  const { data: paused } = useContractQuery({
    address: data?.contract.address,
    args: [],
    contractId: data?.contractType.toLowerCase(),
    functionSelector: data?.id === "auction" ? "isPaused()" : "paused()",
  });
  // console.log("paused", paused);

  const { data: permissive } = useContractQuery({
    address: data?.contract.address,
    args: [],
    contractId: data?.contractType.toLowerCase(),
    functionSelector: "isPermissive()",
  });

  const pauseMutation = useMutation(
    async (pause: boolean) => {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          data?.contract.address,
          ABI,
          signer
        );
        if (pause) {
          const transaction = await contract["pause()"]();
          const result = await transaction.wait();
          return result;
        } else {
          const transaction = await contract["unpause()"]();
          const result = await transaction.wait();
          return result;
        }
        //
      } catch (error) {
        console.error(error);
      }
    },
    {
      onSuccess: (data) => {
        // ["tokenVariable", { address, args, functionSelector }];
        console.log("onSuccess", data);

        queryClient.setQueryData(
          [
            // FIXME: need consisten keys; check them all
            "contractVariable",
            {
              address: data.contract.address,
              args: [],
              functionSelector: "paused()",
            },
          ],
          data.events[0].event === "Paused" ? true : false
        );
      },
    }
  );

  return (
    <div className="p-5 space-y-5 border rounded-lg shadow-2xl">
      <header>
        <h1 className="text-2xl font-bold">Contract State Functions</h1>
        {/* TODO: description of importance and functionality */}
        {/* <p className="text-sm">
          These controls are only available to the Contract Owner, but we'll
          still show the state to everyone.
        </p> */}
      </header>
      <section className="space-y-10">
        <Toggle
          action={toggleActions[0]}
          toggled={!!permissive}
          mutation={pauseMutation}
        />
        <Toggle
          action={toggleActions[1]}
          toggled={!!paused}
          mutation={pauseMutation}
        />
      </section>
    </div>
  );
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const toggleActions = [
  {
    header: "Permissive Mode",
    body: "When Permissive Mode is activated anyone can mint a token (or create an auction) from your contract. Turn off Permissive Mode to restrict minting (or creating) to those authorized.",
    func: () => {},
  },
  {
    header: "Paused",
    body: "When the contract is paused new assets cannot be created.",
    func: () => {},
  },
];

function Toggle({
  action,
  toggled,
  mutation,
}: {
  action: typeof toggleActions[0];
  toggled: boolean;
  mutation: UseMutationResult<any, unknown, boolean, unknown>;
}) {
  // const [enabled, setEnabled] = useState(toggled);

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex-grow flex flex-col">
        <Switch.Label
          as="span"
          className="text-sm font-medium text-gray-900"
          passive
        >
          {action.header}
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          {action.body}
        </Switch.Description>
      </span>
      <Switch
        checked={toggled}
        onChange={() => mutation.mutate(!toggled)}
        className={classNames(
          toggled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            toggled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        >
          {mutation.isLoading && <Spinner />}
        </span>
      </Switch>
    </Switch.Group>
  );
}
