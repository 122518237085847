import { Disclosure } from "@headlessui/react";
import { MenuIcon, SearchIcon, XIcon } from "@heroicons/react/outline";
import firebase from "firebase";
import { Link, useLocation } from "react-router-dom";

import { useMetamask } from "../contexts/metamask.context";
import { CreateMenu } from "./CreateMenu";
import { UserMenu } from "./UserMenu";

export const NavBar = ({ user }: { user: firebase.User | null }) => {
  const location = useLocation();
  const { isConnected } = useMetamask();
  return (
    <Disclosure
      as="nav"
      className="fixed z-20 w-full hidden lg:block bg-white shadow"
    >
      {({ open }) => {
        return (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="-ml-2 mr-2 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <Link
                    to="/"
                    className="flex-shrink-0 flex items-center font-syne"
                  >
                    <img
                      className="block h-9"
                      src="/opera.svg"
                      alt="VertuaLabs"
                    />
                    <h1 className="hidden lg:block ml-2 text-xl font-bold">
                      OperaOmnia
                    </h1>
                  </Link>
                  <div className="hidden md:ml-6 md:flex md:space-x-8">
                    {!!isConnected && (
                      <Link
                        to="/dashboard"
                        className={`${
                          location.pathname.includes("dashboard")
                            ? "border-b-2 border-indigo-600"
                            : null
                        } text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium`}
                      >
                        Dashboard
                      </Link>
                    )}
                  </div>
                  <div className="hidden md:ml-6 md:flex md:space-x-8">
                    <Link
                      to="/markets"
                      className={`${
                        location.pathname.includes("market")
                          ? "border-b-2 border-indigo-600"
                          : null
                      } text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium`}
                    >
                      Markets
                    </Link>
                  </div>
                </div>
                <div className="flex items-center">
                  <Link
                    className="block p-2 mx-4 rounded hover:bg-gray-50"
                    to="/search"
                  >
                    <SearchIcon className="text-black h-6 w-6" />
                  </Link>

                  <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                    {isConnected && <CreateMenu />}
                    <div className="px-2" />
                    <UserMenu />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Disclosure>
  );
};
