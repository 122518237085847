/**
 * One file to rule them all and in the darkness bind them.
 *
 */
import { ethers } from "ethers";
import { QueryKey, useQuery } from "react-query";

import { unpackSnapshot } from "../../utils/unpack_snapshot";
import FirebaseClient from "../firebase/firebase_client";

export const call = async ({
  queryKey,
}: {
  queryKey: QueryKey;
}): Promise<any> => {
  if (typeof window.ethereum !== "undefined") {
    const [, _args] = queryKey as any[];
    // console.log("Args", queryKey, _args);

    const { address, args, contractId, functionSelector } = _args;

    const snapshot = await FirebaseClient.db
      .collection("contracts")
      .doc(contractId)
      .get();

    const { abi } = unpackSnapshot({ snapshot });

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(address, abi, signer);
    // console.log("Found function", contract.functions[functionSelector]);

    try {
      const data = await contract[functionSelector](...args);
      // console.log("Variable Response ", data);
      return data;
    } catch (error) {
      console.error("Error: ", error, _args, contract.functions);
      throw new Error(error);
    }
  }
  throw new Error("Metamask not found");
};

/**
 * Enables calling a Contract via its exposed ABI
 */
export function useContractQuery({
  address,
  args,
  contractId,
  functionSelector,
}: {
  address: string | undefined;
  args: any[];
  contractId: string;
  functionSelector: string | undefined;
}) {
  return useQuery<any, Error>(
    ["contracts", { address, args, contractId, functionSelector }],
    call,
    {
      enabled: !!address && !!args && !!contractId && !!functionSelector,
    }
  );
}

export const useAuctionView = (
  address: string,
  args: any[],
  functionSelector: string
) =>
  useContractQuery({
    address,
    args,
    functionSelector,
    contractId: "auction",
  });

export const useERC20FixedView = (
  address: string,
  args: any[],
  functionSelector: string
) =>
  useContractQuery({
    address,
    args,
    functionSelector,
    contractId: "erc20-fixed",
  });

export const useERC20MinterView = (
  address: string,
  args: any[],
  functionSelector: string
) =>
  useContractQuery({
    address,
    args,
    functionSelector,
    contractId: "erc20-minter",
  });

export const useNFTView = (
  address: string,
  args: any[],
  functionSelector: string
) =>
  useContractQuery({
    address,
    args,
    functionSelector,
    contractId: "nft",
  });
