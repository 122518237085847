import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import FirebaseClient from "../../api/firebase/firebase_client";
import { unpackSnapshotArray } from "../../utils/unpack_snapshot_array";

export const UserPage = () => {
  const { id } = useParams<any>();
  // const publicAddress = useMetamaskAccount();

  const isAddress = id.slice(0, 2) === "0x" ? true : false;
  const key = isAddress ? { publicAddress: id } : { id };

  const { data: nfts } = useQuery(
    ["tokens", { ...key, tokenType: "nft" }],
    async () => {
      const left = isAddress ? "ownedByAddress" : "ownedById";
      const right = id;
      return FirebaseClient.db
        .collection("tokens")
        .where(left, "==", right)
        .where("tokenType", "==", "NFT")
        .get()
        .then((snapshots) => {
          console.log(snapshots);

          return unpackSnapshotArray({ snapshots })
            .sort((a, b) => b.createdAt - a.created)
            .slice(0, 8);
        });
    }
  );

  const { data: erc20s } = useQuery(
    ["tokens", { ...key, tokenType: "erc20" }],
    async () => {
      const left = isAddress ? "ownedByAddress" : "ownedById";
      const right = id;
      return FirebaseClient.db
        .collection("tokens")
        .where(left, "==", right)
        .where("tokenType", "==", "ERC20")
        .get()
        .then((snapshots) => {
          console.log(snapshots);

          return unpackSnapshotArray({ snapshots })
            .sort((a, b) => b.createdAt - a.created)
            .slice(0, 8);
        });
    }
  );

  const { data: auctions } = useQuery(["auctions", key], async () => {
    const left = isAddress ? "ownedByAddress" : "ownedById";
    const right = id;
    return FirebaseClient.db
      .collection("auctions")
      .where(left, "==", right)
      .get()
      .then((snapshots) => {
        console.log(snapshots);

        return unpackSnapshotArray({ snapshots })
          .sort((a, b) => b.createdAt - a.created)
          .slice(0, 8);
      });
  });

  const { data: contracts } = useQuery(["contracts", key], async () => {
    const left = isAddress ? "ownedByAddress" : "ownedById";
    const right = id;
    return FirebaseClient.db
      .collection("contracts")
      .where(left, "==", right)
      .get()
      .then((snapshots) => {
        console.log(snapshots);

        return unpackSnapshotArray({ snapshots })
          .sort((a, b) => b.createdAt - a.created)
          .slice(0, 8);
      });
  });

  const headline = isAddress ? `Address ${id}` : `User ${id}`;

  return (
    <div className="mx-auto max-w-5xl space-y-20">
      <h1 className="text-3xl font-bold">{headline}</h1>

      <section>
        <h1 className="text-3xl font-bold leading-loose">Tokens (NFTs)</h1>
        <ul className="grid grid-cols-4 gap-8">
          {(nfts as any)?.slice(0, 8).map((token: any) => {
            return (
              <Link
                className="block p-2 border border-b-2 text-center rounded shadow hover:shadow-2xl"
                to={`/tokens/${token.id}`}
                key={token.id}
              >
                <img
                  className="mx-auto h-32 w-32 object-scale-down"
                  src={token?.metadata?.imageUri || "/penrose.svg"}
                  alt=""
                />
                <h1>{token?.metadata?.name}</h1>
              </Link>
            );
          })}
        </ul>
      </section>

      <section>
        <h1 className="text-3xl font-bold leading-loose">Tokens (ERC20s)</h1>
        <ul className="grid grid-cols-4 gap-8">
          {(erc20s as any)?.slice(0, 8).map((token: any) => {
            return (
              <Link
                className="block p-2 border border-b-2 text-center rounded shadow hover:shadow-2xl"
                to={`/tokens/${token.id}`}
                key={token.id}
              >
                <img
                  className="mx-auto h-32 w-32 object-scale-down"
                  src={token?.metadata?.imageUri || "/penrose.svg"}
                  alt=""
                />
                <h1>{token?.metadata?.name}</h1>
              </Link>
            );
          })}
        </ul>
      </section>

      <section>
        <h1 className="text-3xl font-bold leading-loose">Auctions</h1>
        <ul className="grid grid-cols-4 gap-8">
          {(auctions as any)?.slice(0, 8).map((auction: any) => {
            return (
              <Link
                className="block p-2 border border-b-2 text-center rounded shadow hover:shadow-2xl"
                to={`/auctions/${auction.id}`}
                key={auction.id}
              >
                <img
                  className="mx-auto h-32 w-32 object-scale-down"
                  src={auction?.tokenMetadata?.imageUri || "/penrose.svg"}
                  alt=""
                />
                <h1>{auction?.auctionMetadata?.name}</h1>
              </Link>
            );
          })}
        </ul>
      </section>

      <section>
        <h1 className="text-3xl font-bold leading-loose">Contracts</h1>
        <ul className="grid grid-cols-4 gap-8">
          {(contracts as any)?.slice(0, 8).map((contract: any) => {
            return (
              <Link
                className="block p-2 border border-b-2 text-center rounded shadow hover:shadow-2xl"
                to={`/contracts/${contract.id}`}
                key={contract.id}
              >
                <img
                  className="mx-auto h-32 w-32 object-scale-down"
                  src={contract?.metadata?.imageUri || "/penrose.svg"}
                  alt=""
                />
                <h1>{contract?.metadata?.name}</h1>
              </Link>
            );
          })}
        </ul>
      </section>

      {/* TODO: */}
      {/* <section>
        <h1 className="text-3xl font-bold leading-loose">
          Associated Accounts (TODO)
        </h1>
        <p>
          For a Firebase Auth.id account, we'll show the associated
          wallet.publicAddresses
        </p>
        <p>
          For a wallet.publicAddress, we'll show the associated Firebase Auth.id
          accounts
        </p>
      </section> */}
      <div className="py-36" />
    </div>
  );
};
