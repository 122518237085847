import { ethers } from "ethers";
import { Field, Form, Formik, FormikHelpers, FormikValues } from "formik";
import { useMutation, useQuery } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { useMetamask } from "../../contexts/metamask.context";
import { unpackSnapshot } from "../../utils/unpack_snapshot";
import { Spinner } from "../network_state/Spinner";

interface Values {
  name: string;
  symbol: string;
  description: string;
}

export const DeployERC20MinterContractForm = () => {
  const { publicAddress } = useMetamask();

  const { data: artifact } = useQuery(
    ["defaultERC20MinterContract"],
    async () => {
      return FirebaseClient.db
        .collection("contracts")
        .doc("erc20-minter")
        .get()
        .then((snapshot) => {
          return unpackSnapshot({ snapshot });
        });
    }
  );

  const deployContractMutation = useMutation(async (values: Values) => {
    if (!publicAddress) {
      console.error("No public address found", publicAddress);

      throw new Error("No public address found");
    }
    try {
      if (!window.ethereum) throw new Error("Metamask not found");

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const factory = ethers.ContractFactory.fromSolidity(
        JSON.parse(artifact?.solidityJSON),
        signer
      );
      // TODO: add in constructor args
      const contract = await factory.deploy(values.name, values.symbol);
      console.log("Contract Deploying tx", contract, contract?.address);

      // we get the address immediately, we can then create a cloud function to create something in our system. this doesn't mean that the transaction has actually been mined yet. see docs <https://docs.ethers.io/v5/api/contract/contract-factory/>
      // TODO: on the server side make a copy of the solidity JSON and abi and store in the new contract document
      FirebaseClient.functions
        .httpsCallable("deployingContract")({
          contract: { address: contract.address },
          deployedByAddress: publicAddress,
          contractType: "erc20-minter",
          metadata: {
            name: values.name,
            symbol: values.symbol,
            description: values.description,
          },
        })
        .then(console.log)
        .catch(console.error);

      await contract.deployed();
      console.log("Contract Deployed tx", contract, contract?.address);

      return contract;
    } catch (error) {
      console.error("DeployContract Error: ", error);
      return { hasError: true };
    }
  });
  return (
    <div className="relative bg-white border rounded-lg shadow-2xl overflow-hidden">
      <Formik
        initialValues={
          {
            name: "",
            symbol: "",
            description: "",
          } as FormikValues
        }
        onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
          deployContractMutation.mutate({
            ...values,
          });
        }}
      >
        {({ values }) => (
          <Form className="">
            <div className="p-8 space-y-5">
              <h2 className="font-semibold">ERC20 Minter Contract Form</h2>
              <p>
                This form creates an ERC20 conforming contract that has an
                unlimited supply of tokens that can minted and burned.
              </p>
              <p>
                You can view the source code for the contract on{" "}
                <a
                  className="underline"
                  href="https://github.com/VertuaLabs/ethereum-smart-contracts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Github
                </a>
                .
              </p>
              {/* CONTRACT NAME */}
              <div className="">
                <label htmlFor="name" className="field-label">
                  Name
                </label>
                <div className="mt-1">
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    className="field-text"
                    placeholder="Contract name e.g., VertuaLabsMinter (VLMT)"
                  />
                </div>
              </div>

              {/* CONTRACT SYMBOL*/}
              <div className="">
                <label htmlFor="symbol" className="field-label">
                  Symbol
                </label>
                <div className="mt-1">
                  <Field
                    type="text"
                    name="symbol"
                    id="symbol"
                    className="field-text"
                    placeholder="Contract symbol (e.g., VLMT)"
                  />
                </div>
              </div>

              {/* DESCRIIPTION */}
              <div className="mt-3">
                <label htmlFor="message" className="field-label">
                  Description
                </label>
                <div className="mt-1">
                  <Field
                    as="textarea"
                    type="textarea"
                    name="description"
                    id="description"
                    rows={2}
                    className="field-text"
                    placeholder="Description"
                  />
                </div>
              </div>
            </div>

            <div className="px-8 py-2 flex justify-end space-x-5 bg-gray-50 mt-2">
              <button
                type="reset"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md text-white bg-gray-300 shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <span>Reset</span>
              </button>
              <button
                type="submit"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {deployContractMutation.isLoading && <Spinner />}
                <span>Deploy Contract</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
