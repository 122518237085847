import { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import FirebaseClient from "../api/firebase/firebase_client";
import { AboutPage } from "../pages/about/AboutPage";
import { AccountPage } from "../pages/accounts/AccountPage";
import { AdminPage } from "../pages/admin/AdminPage";
import { CreateAuctionPage } from "../pages/auctions/CreateAuction";
import ConnectPage from "../pages/auth/ConnectPage";
import { ComingSoon } from "../pages/ComingSoon";
import { ContractPage } from "../pages/contracts/ContractPage";
import { DeployContract } from "../pages/contracts/DeployContract";
import Dashboard from "../pages/Dashboard";
import HomePage from "../pages/Home";
import MarketPage from "../pages/markets/MarketPage";
import { SearchPage } from "../pages/search/SearchPage";
import { MintNFTPage } from "../pages/tokens/MintNFT";
import { TokenPage } from "../pages/tokens/TokenPage";
import { UserPage } from "../pages/users/UserPage";
import { ErrorBoundary } from "./ErrorBoundary";

export class Router extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV !== "production") {
      console.group("ROUTER ERROR: COMPONENT DID CATCH");
      console.error(error, info);
      console.groupEnd();
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <Suspense fallback={<div>loading...</div>}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/dashboard" component={Dashboard} />

            <Route exact path="/about" component={AboutPage} />

            {/* AUTH ------------------------------------------------------- */}
            <Route exact path="/connect" component={ConnectPage} />

            <Route exact path="/contracts/deploy" component={DeployContract} />
            <Route exact path="/contracts/:id" component={ContractPage} />

            <Route exact path="/tokens/mint/nft" component={MintNFTPage} />
            <Route exact path="/tokens/:id" component={TokenPage} />

            <Route exact path="/tokens/mint/erc20" component={ComingSoon} />
            <Route exact path="/tokens/mint/dao" component={ComingSoon} />

            <Route
              exact
              path="/auctions/create"
              component={CreateAuctionPage}
            />

            <Route exact path="/users/:id" component={UserPage} />

            <Route exact path="/markets" component={MarketPage} />
            <Route exact path="/search" component={SearchPage} />

            <Route exact path="/admin" component={AdminPage} />
            <Route exact path="/account" component={AccountPage} />
            <PrivateRoute>
              <Route exact path="/account" component={AccountPage} />
            </PrivateRoute>

            <Route path="/:slug">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

function PrivateRoute({ children, ...rest }: { children: any; rest?: any }) {
  const { currentUser } = FirebaseClient.auth;
  // if (process.env.NODE_ENV !== "production") {
  //   console.log("Current user: ", currentUser);
  // }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
