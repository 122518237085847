import { ethers } from "ethers";
import { QueryKey, useQuery } from "react-query";

import { unpackSnapshot } from "../../../utils/unpack_snapshot";
import FirebaseClient from "../../firebase/firebase_client";

const mapResultToObject = (result: any): any => {
  const obj: any = {};
  Object.keys(result).forEach((key: any) => {
    if (Array.isArray(result[key])) {
      obj[key] = mapResultToObject(result[key]);
    } else {
      obj[key] = result[key];
    }
  });
  return obj;
};

export const auctions = async ({ queryKey }: { queryKey: QueryKey }) => {
  if (typeof window.ethereum !== "undefined") {
    const snapshot = await FirebaseClient.db
      .collection("contracts")
      .doc("auction")
      .get();

    const { abi } = unpackSnapshot({ snapshot });

    const [, { address, id }] = queryKey as any[];
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(address, abi, provider);

    try {
      const data = await contract["auctions(uint256)"](id);
      // console.log("Auction Struct", data, Object.keys(data), data.__proto__);

      // FIXME: for some reason we need to spread this for it to work?
      // FIXME: when I return 'data', i'm somehow losing the getters for the fields and just get an array. solution is i need to use deepCopy? but why
      // This is stupid. I'm not understanding something
      // const copy = ethers.utils.deepCopy(data);
      // const copy = Object.assign([], data);
      // const copy = data.concat();
      const copy = mapResultToObject(data);

      // copy.length = 18;
      // console.log("Auction Struct Copy", copy);

      return copy;
    } catch (err) {
      console.error("Error: ", err);
      return "";
    }
  }
  return "";
};

export const useAuctions = ({
  address,
  id,
}: {
  address: string | undefined;
  id: string | undefined;
}) => {
  return useQuery(["auctionStructs", { address, id }], auctions, {
    enabled: !!address && !!id,
  });
};
