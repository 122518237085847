export const ComingSoon = () => {
  return (
    <div className="min-h-screen text-center">
      <img
        className="mx-auto animate-pulse"
        src="/opera.svg"
        alt="opera cake icon"
      />
      <h1 className="text-2xl italic">Coming soon!</h1>
    </div>
  );
};
