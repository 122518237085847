// import { authService } from "../../xstate/authMachine";
import FirebaseClient from "./firebase_client";

export async function doSignOut() {
  try {
    // TODO: SIGNING_OUT State
    // TODO: disconnect wallet
    // see: <https://github.com/MetaMask/metamask-extension/issues/8990>
    await FirebaseClient.auth.signOut();
    // authService.send({ type: "SIGNED_OUT" });
    await window.ethereum.request({
      method: "wallet_requestPermissions",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
    return { hasError: false };
  } catch (error) {
    // even if an error, still make sure we sign out the user and clear all caches as that's the user's intent
    // authService.send({ type: "SIGNED_OUT" });
    await window.ethereum.request({
      method: "wallet_requestPermissions",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
    return { hasError: true, error };
  }
}
