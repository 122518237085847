import { Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { useQuery } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { useAuctionName } from "../../api/metamask/auctions/use_auction_name";
import { Auction } from "../../types/firestore";
import { unpackSnapshot } from "../../utils/unpack_snapshot";

export const AuctionDetailsDisclosure = ({ auction }: { auction: Auction }) => {
  const { data: auctionContractName } = useAuctionName({
    address: auction?.auctionContract?.address,
  });

  const { data: contract } = useQuery(
    ["contracts", { address: auction?.auctionContract?.address }],
    async () => {
      if (!auction) return;
      return FirebaseClient.db
        .collection("contracts")
        .where("contract.address", "==", auction?.auctionContract?.address)
        .get()
        .then((snapshots) => {
          if (snapshots.empty) return;
          const contract = unpackSnapshot({ snapshot: snapshots.docs[0] });
          return contract;
        });
    },
    { enabled: !!auction }
  );

  return (
    <Disclosure>
      {({ open }) => (
        <>
          {/* TODO: need to use a portal to get this to render correctly */}
          <Disclosure.Button className="relative bg-white flex items-center justify-between w-full p-2 border rounded shadow-sm">
            <span className="text-sm font-bold whitespace-nowrap">
              Auction Instance Details
            </span>
            {/*
              Use the `open` render prop to rotate the icon when the panel is open
            */}
            <ChevronRightIcon
              className={`h-5 inline ${open ? "transform rotate-90" : ""}`}
            />
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="relative bg-white w-full">
              <div className="mt-2 p-2 flex items-center border shadow rounded">
                <img
                  className="h-14"
                  src={"/smart_contract.svg"}
                  alt="smart contract icon"
                />
                <div className="">
                  <dl className="">
                    <dt className="text-xs">Auction Contract Name</dt>
                    {/* TODO: link to the internal contract page */}
                    <dd className="text-base font-semibold">
                      {auctionContractName || contract?.metadata?.name}
                    </dd>
                  </dl>

                  <a
                    className="inline-flex items-center text-gray-500 text-sm underline"
                    href={`https://ropsten.etherscan.io/address/${auction?.auctionContract?.address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Contract on Etherscan
                    <ExternalLinkIcon className="h-4 text-gray-500" />
                  </a>
                </div>
              </div>
              <dl className="p-2 mt-2 space-y-2 border shadow rounded">
                <div>
                  <dt className="text-xs text-gray-500 font-bold">
                    Auction Id
                  </dt>
                  <dd className="col-span-4 text-sm truncate">
                    {auction?.auctionContract?.id}
                  </dd>
                </div>

                <div>
                  <dt className="text-xs text-gray-500 font-bold">State</dt>
                  <dd className="col-span-4 text-sm truncate">
                    {auction?.state}
                  </dd>
                </div>

                <div>
                  <dt className="text-xs text-gray-500 font-bold">
                    Created by User Id
                  </dt>
                  <dd className="col-span-4 text-sm truncate">
                    {auction?.requestedById}
                  </dd>
                </div>

                <div>
                  <dt className="text-xs text-gray-500 font-bold">
                    Created by Address
                  </dt>
                  <dd className="col-span-4 text-sm truncate">
                    {auction?.requestedByAddress}
                  </dd>
                </div>
              </dl>{" "}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
