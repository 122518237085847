import { ethers } from "ethers";

const abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_auctionId",
        type: "uint256",
      },
    ],
    name: "startAuction",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const startAuction = async ({
  address,
  id,
}: {
  address: string;
  id: string;
}) => {
  if (typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(address, abi, signer);
    console.log("Found contract", address, contract);

    try {
      const transaction = await contract.startAuction(id);
      console.log("transaction", transaction);
      const result = await transaction.wait();
      console.log("transaction completed", result);
      return result;
    } catch (error) {
      console.error("startPreAuction Error:", error);
    }
  } else {
    console.log("couldn't find window.ethereum");
  }
};
