import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter, useLocation } from "react-router-dom";

import FirebaseClient from "./api/firebase/firebase_client";
import { queryClient } from "./api/react_query/react_query";
import { Footer } from "./components/Footer";
import { EthereumNetworkProvider } from "./contexts/ethereum_network.context";
import { MetamaskProvider } from "./contexts/metamask.context";
import { FirebaseAuthProvider } from "./hooks/firebase_user.context";
import { MobileNavBar } from "./nav/MobileNavBar";
import { NavBar } from "./nav/NavBar";
import { NetworkStateBanner } from "./router/NetworkStateBanner";
import { Router } from "./router/Router";
import { ScrollToTop } from "./router/ScrollToTop";

// Hack to make typescript not yell about window.ethereum
declare global {
  interface Window {
    ethereum: any;
  }
}

function PageViews() {
  let location = useLocation();

  React.useEffect(() => {
    FirebaseClient.analytics.logEvent("page_view", {
      page_location: location.pathname,
      page_path: window.location.pathname,
    });
  }, [location]);

  return <></>;
}

export const App = () => {
  const [user, setUser] = useState(FirebaseClient.auth?.currentUser);

  useEffect(() => {
    return FirebaseClient.auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("The user is logged in");
        setUser(user);
      } else {
        setUser(null);
        console.log("The user is not logged in", user);
      }
    });
  }, []);

  return (
    <div className="relative font-space">
      <BrowserRouter>
        <PageViews />
        <QueryClientProvider client={queryClient}>
          <FirebaseAuthProvider>
            <MetamaskProvider>
              <NavBar user={user} />
              <MobileNavBar user={user} />
              <div className="pb-16" />
              <NetworkStateBanner />
              <div className="pb-16" />

              <ScrollToTop />
              <Router />
            </MetamaskProvider>
          </FirebaseAuthProvider>
          <Footer />
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
