import { QueryKey, useQuery } from "react-query";

import { unpackSnapshot } from "../../utils/unpack_snapshot";
import FirebaseClient from "../firebase/firebase_client";

export const view = async ({
  queryKey,
}: {
  queryKey: QueryKey;
}): Promise<any> => {
  let [, { type }] = queryKey as any[];

  // Get the default contract from the server and use its ABI
  const snapshot = await FirebaseClient.db
    .collection("contracts")
    .doc(type.toLowerCase())
    .get();

  const baseContract = unpackSnapshot({ snapshot });

  return baseContract;
};

/**
 * Enables calling a Contract's view function
 * @deprecated in favor of useContractView and useContractFunction
 */
export function useBaseContract({ type }: { type: string | undefined }) {
  return useQuery<any, Error>(["baseContract", { type }], view, {
    enabled: !!type,
  });
}
