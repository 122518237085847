import { CheckIcon, CloudUploadIcon, RefreshIcon, SearchIcon } from "@heroicons/react/outline";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { LandingHero } from "../components/LandingHero";

export const Home = () => {
  return (
    <div className="pb-24">
      <Helmet>
        <title>Opera Omnia</title>
      </Helmet>

      <div className="-mt-16" />
      <LandingHero />

      <main className="">
        <section className="relative mx-auto max-w-7xl py-32 md:grid md:grid-cols-12 place-items-center">
          <h1 className="p-10 md:col-span-5 text-4xl md:text-6xl text-indigo-500 text-left md:text-right">
            A smart way <br /> to smart <br /> contracts
          </h1>
          <p className="p-10 md:col-span-7 text-2xl font-space">
            OperaOmnia gives you simple solutions to the normally tedious task
            of <span className="font-bold">creating, deploying,</span> and{" "}
            <span className="font-bold">maintaining</span> smart contracts.
          </p>
        </section>

        <DeployContracts />

        <div className="bg-white">
          <CardMatrix />
        </div>

        <LogoCloud />

        <Pricing />
      </main>
    </div>
  );
};
export default Home;

const DeployContractsCopy = [
  {
    h1: "Use Standard Contracts",
    body: "Easy to use standard smart contracts allows you to take ownership and start creating today everything from NFTs, to ERC20 tokens, to auctions, and more!",
    img: "",
    icon: RefreshIcon,
  },
  {
    h1: "Find Contracts via Contract Marketplace",
    body: "Search the Marketplace to find more Smart Contracts to deploy. Allow others to deploy your contracts.",
    img: "",
    icon: SearchIcon,
  },
  {
    h1: "Add Existing Contracts",
    body: "Already have a Smart Contract? No problem! Add to your Command Center today!",
    img: "",
    icon: CloudUploadIcon,
  },
];

const DeployContracts = () => {
  return (
    <div className="relative px-5 md:px-0 py-32 bg-black bg-opacity-5">
      <div className="mx-auto max-w-7xl grid grid-cols-1 md:grid-cols-12 place-items-start text-right">
        <div className="md:col-span-4 space-y-4">
          <h1 className="text-3xl text-indigo-600 font-syne font-bold">
            Deploy Your Own <br /> Contracts
          </h1>
          <p className="text-lg font-space">
            OperaOmnia gives you the ability to select and deploy your own Smart
            Contracts. By deploying your own smart contracts, you retain full
            custody of them and can be sure that you have total control{" "}
          </p>
        </div>
        <div className="mt-10 md:mt-0  md:col-start-6 md:col-span-7 text-left">
          <dl className="space-y-10">
            {DeployContractsCopy.map((item) => (
              <div key={item.h1} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {item.h1}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {item.body}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

const CardCopy = [
  {
    h1: "Deploy Contracts",
    body: "Start deploying standard OperaOmnia SmartContracts with the push of a button today!",
    icon: "/contract-icon.svg",
    to: "/contracts/deploy",
    btn: "Deploy a Contract",
  },
  {
    h1: "Create Auctions",
    body: "We provide a simple highest bid wins Auction system. Alternatively, deploy your own Auction Contract and start auctioning today!",
    icon: "/auction-icon.svg",
    to: "/auctions/create",
    btn: "Create an Auction",
  },
  {
    h1: "Mint Tokens",
    body: "Create new NFTs in our standard contract. Or, deploy your own NFT or ERC-20 token contracts!",
    icon: "/minter-icon.svg",
    to: "/tokens/mint/nft",
    btn: "Mint a Token",
  },
  {
    h1: "Market Storefront",
    body: "Create your own personalized storefront to show off your contracts and tokens created within your contracts - or elsewhere.",
    icon: "/storefront-icon.svg",
    to: "/markets",
    btn: "Coming Soon...",
  },
  {
    h1: "Create DAOs",
    body: "Easy configuration and push to deploy your own DAO.",
    icon: "/contract-icon.svg",
    to: "/",
    btn: "Coming Soon...",
  },
];

const Spacer = () => (
  <div className="flex-grow flex-shrink0 wfull bg-white">*</div>
);

const CardMatrix = () => {
  return (
    <section className="relative p-5 md:px-0 py-32 bg-white mx-auto max-w-7xl overflow-hidden">
      <h1 className="text-4xl md:text-6xl text-indigo-600 font-syne font-bold">
        OperaOmnia <br />
        empowers you
      </h1>
      <div className="mt-32" />
      <dl
        className="-m-1 bg-center bg-cover grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 grid-flow-row-dense overflow-hidden"
        style={{ backgroundImage: "url(josh-rose-trYl7JYATH0-unsplash.png)" }}
      >
        {CardCopy.map((card, i) => {
          return (
            <div>
              <div
                className="relative mx-auto h-500 w-full border-b-8 border-white text-white text-center overflow-hidden"
                key={card.h1}
              >
                <div className="flex justify-between h-full">
                  <Spacer />
                  {/*  */}
                  <div className="p-10 max-w-md flex-shrink0 flex flex-col text-white">
                    <div className="mx-auto">
                      <div className="bg-indigo-600 h-16 w-16 rounded-full" />
                      <img
                        className="-mt-14 ml-2 h-16 w-16"
                        src={card.icon}
                        alt=""
                      />
                    </div>
                    <h1 className="mt-10 text-3xl font-syne">{card.h1}</h1>
                    <h2 className="flex-grow mt-10 text-base font-space">
                      {card.body}
                    </h2>
                    {i < 3 ? (
                      <Link
                        type="button"
                        to={card.to}
                        className="inline-block my-10 relative items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {card.btn}
                      </Link>
                    ) : (
                      <p className="my-10 text-sm font-bold">Coming soon...</p>
                    )}
                  </div>
                  {/*  */}
                  <Spacer />
                </div>
              </div>
              <div className="h-2 bg-white" />
            </div>
          );
        })}

        <div className="relative p-10 h-full w-full space-y-5 bg-white text-center md:text-right overflow-hidden">
          <h1 className="mt-10 text-indigo-600 text-4xl font-syne font-bold">
            & more <br /> coming soon
          </h1>
          {/* TODO: email form */}
          <p className="text-sm font-space">
            Leave us your email to remain <br /> updated on our latest releases
          </p>
          <form
            action="https://app.us5.list-manage.com/subscribe/post?u=730f4ea61b923a9e939b6f7ca&amp;id=cb8f272e89"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <input
              className="p-2 border"
              type="email"
              placeholder="Your email"
              autoComplete="email"
              name="EMAIL"
              id="mce-EMAIL"
              required
            />
            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_730f4ea61b923a9e939b6f7ca_cb8f272e89"
                tabIndex={-1}
                value=""
              />
            </div>

            <br />
            <button
              className="mt-4 relative inline-block items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
            >
              Subscribe
            </button>
          </form>

          <br />
          {/* <Link
            to="/connect"
            className="mt-14 relative inline-block items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Get started
          </Link> */}
        </div>
      </dl>
    </section>
  );
};

/* This example requires Tailwind CSS v2.0+ */
const LogoCloud = () => {
  return (
    <div className="bg-indigo-200 bg-opacity-25">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <h2 className="max-w-md mx-auto text-3xl font-extrabold text-indigo-900 text-center lg:max-w-xl lg:text-left">
            Supporting the world's blockchains
          </h2>
          <div className="flow-root self-center mt-8 lg:mt-0">
            <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <img
                  className="h-12"
                  src="/ethereum-logotype.png"
                  alt="Ethereum"
                />
              </div>
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                {/* <img
                  className="h-12"
                  src="/polkadot-logotype.png"
                  alt="Polkadot"
                /> */}
              </div>
              {/* FIXME: spacing */}
              <div className="invisible mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                {/* <img
                  className="h-12"
                  src="/polkadot-logotype.png"
                  alt="Polkadot"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* This example requires Tailwind CSS v2.0+ */

const Tiers = [
  {
    name: "Free",
    href: "/connect",
    priceMonthly: 0,
    description:
      "Everything you need to get started creating in the -verse. Start deploying and controlling your assets today!",
    features: [
      "Deploy Your Own Contracts",
      "Basic Dashboard and Contraft Interface",
      "Mint Tokens",
      "Create Auctions",
    ],
    btn: "Get started",
  },
  {
    name: "Professional",
    href: "/",
    priceMonthly: 42,
    description:
      "Get a 360 view on all of your assets, track analytics, and customize your own Omnia Storefront.",
    features: [
      "Personalizable Storefront",
      "Advanced Analytics",
      "Advanced Admin System",
      "Complete Contract History Tracking",
    ],
    btn: "Coming soon!",
  },
];

const Pricing = () => {
  return (
    <div
      className="bg-cover font-space"
      style={{ backgroundImage: "url(/pricing-bg.png)" }}
    >
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
              Pricing
            </h2>
            <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl font-syne">
              The right price for you, whoever you are
            </p>
            <p className="text-xl text-gray-300">
              No matter your needs, if you are just starting out or if you need
              a 360/24/7 coverage, we have you covered.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12  sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              {Tiers.map((tier) => (
                <div
                  key={tier.name}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
                        id="tier-standard"
                      >
                        {tier.name}
                      </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-6xl font-extrabold font-syne-mono">
                      ${tier.priceMonthly}
                      <span className="ml-1 text-2xl font-medium text-gray-500">
                        /mo
                      </span>
                    </div>
                    <p className="mt-5 text-lg text-gray-500">
                      {tier.description}
                    </p>
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon
                              className="h-6 w-6 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-base text-gray-700">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div className="rounded-md shadow">
                      <Link
                        to={tier.href}
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                        aria-describedby="tier-standard"
                      >
                        {tier.btn}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
