import { Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useQuery } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { useContractQuery } from "../../api/metamask/use_contract_query";
import { Token } from "../../types/firestore";
import { unpackSnapshot } from "../../utils/unpack_snapshot";

export const TokenDetailsDisclosure = ({ token }: { token: Token }) => {
  const { data: contractName } = useContractQuery({
    address: token?.contract.address,
    args: [],
    contractId: token?.tokenType.toLowerCase(),
    functionSelector: "name()",
  });
  const { data: contractSymbol } = useContractQuery({
    address: token?.contract.address,
    args: [],
    contractId: token?.tokenType.toLowerCase(),
    functionSelector: "symbol()",
  });

  const { data: contract } = useQuery(
    ["contracts", { address: token?.contract?.address }],
    async () => {
      if (!token) return;
      return FirebaseClient.db
        .collection("contracts")
        .where("contract.address", "==", token?.contract?.address)
        .get()
        .then((snapshots) => {
          if (snapshots.empty) return;
          const contract = unpackSnapshot({ snapshot: snapshots.docs[0] });
          return contract;
        });
    },
    { enabled: !!token }
  );
  // console.log("Contract", contract);

  const metadataUri = `https://storage.googleapis.com/opera-omnia-test.appspot.com/nfts/${token?.contract?.id}.json`;

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="relative flex items-center justify-between bg-white w-full p-2 border rounded shadow-sm">
            <span className="text-sm font-bold whitespace-nowrap">
              Token Instance Details
            </span>
            {/*
              Use the `open` render prop to rotate the icon when the panel is open
            */}
            <ChevronRightIcon
              className={`h-5 inline ${open ? "transform rotate-90" : ""}`}
            />
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="relative bg-white">
              <div className="mt-2 p-2 flex border items-center rounded shadow">
                <img
                  className="h-14"
                  src={"/smart_contract.svg"}
                  alt="smart contract icon"
                />
                <div className="flex flex-col">
                  <dl className="">
                    <dt className="sr-only text-xs">NFT Contract Name</dt>
                    {/* TODO: link to the internal contract page */}
                    <dd className="text-sm font-semibold">
                      {contractName || contract?.metadata?.name}
                    </dd>
                    <dt className="sr-only text-xs">NFT Contract Symbol</dt>
                    {/* TODO: link to the internal contract page */}
                    <dd className="text-sm font-semibold">{`(${
                      contractSymbol || contract?.metadata?.symbol
                    })`}</dd>
                  </dl>
                  <a
                    className="inline-flex items-center text-gray-500 text-xs underline"
                    href={`https://ropsten.etherscan.io/address/${token?.contract?.address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Contract on Etherscan
                  </a>
                  <a
                    className="inline-flex items-center text-gray-500 text-xs underline"
                    href={`https://ropsten.etherscan.io/token/${token?.contract?.address}?a=${token?.contract?.id}#inventory`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Token on Etherscan
                  </a>
                  <a
                    className="inline-flex items-center text-gray-500 text-xs underline"
                    href={`${metadataUri}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Metadata JSON
                  </a>
                </div>
              </div>
              {/* Details List */}
              <dl className="p-2 mt-2 space-y-2 border shadow rounded">
                <div>
                  <dt className="text-xs text-gray-500 font-bold">Token Id</dt>
                  <dd className="col-span-4 text-sm truncate">
                    {token?.contract?.id}
                  </dd>
                </div>

                <div>
                  <dt className="text-xs text-gray-500 font-bold">
                    Owner Addresss
                  </dt>
                  <dd className="col-span-4 text-sm truncate">
                    {token?.ownedByAddress}
                  </dd>
                </div>

                <div>
                  <dt className="text-xs text-gray-500 font-bold">State</dt>
                  <dd className="col-span-4 text-sm truncate">
                    {token?.state}
                  </dd>
                </div>

                <div>
                  <dt className="text-xs text-gray-500 font-bold">Minted at</dt>
                  <dd className="col-span-4 text-sm truncate">
                    {new Date(token?.createdAt).toLocaleString()}
                  </dd>
                </div>

                <div>
                  <dt className="text-xs text-gray-500 font-bold">
                    Minted by User Id
                  </dt>
                  <dd className="col-span-4 text-sm truncate">
                    {token?.requestedById}
                  </dd>
                </div>

                <div>
                  <dt className="text-xs text-gray-500 font-bold">
                    Minted by Address
                  </dt>
                  <dd className="col-span-4 text-sm truncate">
                    {token?.requestedByAddress}
                  </dd>
                </div>
              </dl>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
