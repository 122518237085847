import { useContext } from "react";
import { Helmet } from "react-helmet";

import { useContractQuery } from "../../api/metamask/use_contract_query";
import { AdminControlPanel } from "../../components/contracts/AdminControlPanel";
import { ContractHeader } from "../../components/contracts/ContractHeader";
import { ContractInterfacePanel } from "../../components/contracts/ContractInterface";
import { ContractContext } from "./ContractPage";

/**
 *
 */
export const AuctionContract = () => {
  const data = useContext(ContractContext);

  const { data: contractOwner } = useContractQuery({
    address: data?.contract.address,
    args: [],
    contractId: data?.id,
    functionSelector: "owner()",
  });
  const { data: contractName } = useContractQuery({
    address: data?.contract.address,
    args: [],
    contractId: data?.id,
    functionSelector: "name()",
  });

  const header = `${contractName || data?.metadata?.name}`;
  const subheader = "Auction Contract";

  return (
    <div className="p-5 lg:p-0 mx-auto max-w-7xl">
      <Helmet>
        <title>{header} Contract Page</title>
      </Helmet>
      <ContractHeader contract={data} header={header} subheader={subheader} />

      <main>
        <div className="py-10" />
        {/* <AuctionAdminControlPanel /> */}
        <AdminControlPanel />

        <div className="py-10" />
        <ContractInterfacePanel />
      </main>
      <div className="py-72" />
    </div>
  );
};
