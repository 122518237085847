import { ethers } from "ethers";
import { QueryKey, useQuery } from "react-query";

const ABI = ["function name() public view returns (string)"];

export const view = async ({ queryKey }: { queryKey: QueryKey }) => {
  if (typeof window.ethereum !== "undefined") {
    const [, { address }] = queryKey as any[];
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(address, ABI, provider);

    try {
      const data = await contract["name()"]();
      return data;
    } catch (err) {
      console.error("Error: ", err);
      return "";
    }
  }
  return "";
};

export const useAuctionName = ({
  address,
}: {
  address: string | undefined;
}) => {
  return useQuery(["auctionContractName", { address }], view, {
    enabled: !!address,
  });
};
