import { Link } from "react-router-dom";

export const LandingHero = () => {
  return (
    <div
      className="relative h-800 bg-cover bg-no-repeat overflow-hidden"
      style={{
        backgroundImage: "url(/hero-bg.jpg)",
      }}
    >
      {/* <div className="absolute inset-0 w-full h-full bg-black bg-opacity-40" /> */}

      <div className="rlative mx-auto max-w-7xl h-full z-10 grid grid-cols-1 md:grid-cols-12 place-items-center text-gray-200">
        {/* <div className="text-white">hello</div> */}
        <div className="md:col-span-4 p-5 lg:p-0 max-w-sm space-y-5">
          <p className="text-indigo-300 font-bold">
            A Smart Way to Smart Contract
          </p>
          <h1 className="text-5xl font-bold">
            Your Blockchain and Token Command Center
          </h1>
          <h2 className="font-space">
            Deploy and Manage Smart Contracts, Tokens, NFTs, Auctions, DAOs, and
            More
            {/* No code solution to harnassing the full potential of the
            Blockchain-verses. You can now create and control all your assets
            with the push of a button. With VertuaVerse you are in full control
            with the ability to deploy new Smart Contracts, create Auctions, and
            mint Tokens (NFTs and ERC20 support). */}
          </h2>
          <div className="flx space-x-5">
            <Link
              to="/connect"
              className="inline-block bg-indigo-500 px-4 py-2 text-white rounded hover:shadow-xl"
            >
              Get Started
            </Link>

            {/* <Link
              to="/about"
              className="inline-block bg-white px-4 py-2 text-indigo-500 rounded hover:shadow-xl"
            >
              Learn more
            </Link> */}
          </div>
        </div>
        {/* <div className="relative place-self-center w-full"> */}
        <div />
        {/* <img
          className="absolute md:col-span-7 hidden md:block wfull object-right object-scale-down"
          src="hero-inset.png"
          alt="Deploy contract app screenshot"
        /> */}
        {/* </div> */}
      </div>
      <div className="absolute z-0 max-w-md lg:max-w-4xl h-full hidden md:flex flex-col top-0 right-0">
        <img
          className="my-auto object-right object-scale-down"
          src="hero-inset.png"
          alt="Deploy contract app screenshot"
        />
      </div>
    </div>
  );
};

export const LandingHeroOld = () => {
  return (
    <div
      className="relative p-10 bg-cover overflow-hidden"
      style={{
        backgroundImage:
          "url(https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1234&q=80)",
      }}
    >
      <div className="flex h-48 md:h-96 mx-auto max-w-5xl">
        {/* Inset Image */}
        <img
          className="z-10  md:h-80 my-auto object-scale-down rounded-xl"
          src={
            "https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1234&q=80"
          }
          alt=""
        />

        <div className="relative -ml-32 flex w-full bg-indigo-400 col-span-8 text-gray-50 rounded-xl overflow-hidden">
          {/* Left */}
          <svg
            className="absolute right-full transform translate-y-2/3 translate-x-2/3"
            width={404}
            height={284}
            fill="none"
            viewBox="0 0 404 484"
          >
            <defs>
              <pattern
                id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-300"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
            />
          </svg>
          {/* Right */}
          <svg
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
            width={404}
            height={284}
            fill="none"
            viewBox="0 0 404 484"
          >
            <defs>
              <pattern
                id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-300"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
            />
          </svg>
          <div className="hidden md:block relative z-10 ml-48 my-auto space-y-10">
            <h1 className="text-3xl">Mint, Auction, and Deploy</h1>
            <h2 className="text-lg">
              Welcome to the VertuaVerse, your new home for creating your
              crypto-enabled future. What will you build?
            </h2>
            {/* <Link
              className="bg-white px-4 py-2 text-indigo-500 rounded hover:shadow-xl"
              to="/about"
            >
              Learn more
            </Link> */}
          </div>
        </div>
      </div>
      <div className="relative md:hidden z-10 text-center text-white my-auto space-y-10">
        <h1 className="text-3xl">Mint, Auction, and Deploy</h1>
        <h2 className="text-lg">
          Welcome to the VertuaVerse, your new home for creating your
          crypto-enabled future. What will you build?
        </h2>
        <button className="bg-white px-4 py-2 text-indigo-500 rounded hover:shadow-xl">
          Get started
        </button>
      </div>
    </div>
  );
};
