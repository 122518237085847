import { ArrowRightIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { ethers } from "ethers";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { useRecentBids } from "../../api/firebase/auctions/use_recent_bids";
import FirebaseClient from "../../api/firebase/firebase_client";
import { bidWithETH } from "../../api/metamask/auctions/bid_eth";
import { useAuctions } from "../../api/metamask/auctions/use_auctions";
import { useMetamask } from "../../contexts/metamask.context";
import { Auction, AuctionBid } from "../../types/firestore";
import { Spinner } from "../network_state/Spinner";

/**
 * Auction Panel
 *
 * USD Bids
 * - best to use Stripe markeplace for this?
 * <https://stripe.com/use-cases/marketplaces>
 *
 * - Places a hold on a credit token
 * <https://github.com/stripe-samples/placing-a-hold>
 *
 * - helpful examples
 * <https://github.com/stripe-samples>
 *
 * think we need to use Stripe Connect
 * <https://dashboard.stripe.com/connect/settings/profile>
 *
 * - for sellers we'll need to onboard them to stripe in order to be able to pay them
 * <https://stripe.com/docs/connect/express-accounts>
 *
 * TODO: this panel needs to listen for newer bids in order to change the 'must bid more than' field
 *
 *
 */
export const AuctionPanel = ({ auction }: { auction: Auction | undefined }) => {
  // const stripe = useStripe();
  // const elements = useElements();
  const [paymentIntent, setPaymentIntent] = useState("");
  const [state, setState] = useState("disconnected");
  const [modalMode, setModalMode] = useState("");
  const [billingName, setBillingName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [bidType, setBidType] = useState("eth");

  const { bids } = useRecentBids({ auction });

  const { isConnected } = useMetamask();
  // console.log("isConnected", isConnected);

  const { data: auctionStruct } = useAuctions({
    address: auction?.auctionContract.address,
    id: auction?.auctionContract.id,
  });
  console.log("Auction Struct", auctionStruct);

  const { data: conversion } = useQuery(["eth2usd"], async () =>
    fetch("https://cex.io/api/last_price/ETH/USD").then(async (response) => {
      const json = await response.json();
      // console.log(json);
      return json?.lprice;
    })
  );
  // console.log("conversion", conversion);

  useEffect(() => {
    if (!auction) return;
    if (!window.ethereum) return;
    // TODO: need to index emitted event
    const ABI = [
      "event HighestBidIncreased(uint256 auctionId, address bidder, uint256 amount, uint256 usdAmount, string comment)",
    ];

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      auction.auctionContract.address,
      ABI,
      provider
    );
    console.log("Contract", contract);
    // const hexId = ethers.utils.hexlify(data?.contract.id);
    console.log("Token Id", auction.auctionContract.id);

    const filter = contract.filters?.HighestBidIncreased();
    // TODO: this should be a listener
    contract.queryFilter(filter, 0, "latest").then((events) => {
      console.log("Auction Events", events);
      events = events
        .filter(
          (event) =>
            event.args?.auctionId.toString() === auction?.auctionContract?.id
        )
        .sort((a, b) => b.blockNumber - a.blockNumber);

      // setTokenEvents(events);
    });
  }, [auction]);

  // TODO: create utility functions for these calculations
  // TODO: make sure we update Auction.currentBid on new bids
  const ethBid = auctionStruct?.highestBid?.bid
    ? ethers.utils.formatEther(auctionStruct.highestBid.bid)
    : ethers.utils.formatEther(auction?.currentBid || 0);
  // console.log("ETH BID", ethBid);

  const reserve = ethers.utils.formatEther(auctionStruct?.reserve || 0);
  const reserveMet = auctionStruct?.state === 3;

  return (
    <div className="p-12 bg-white rounded-xl border shadow-2xl overflow-hidden">
      <header className="space-y-4">
        <div className="flex items-baseline space-x-4">
          <h1 className="text-3xl font-bold">Place a bid higher than</h1>
          <h2 className="text-6xl font-syne-mono">
            <span className="text-indigo-600 font-bold">{ethBid}</span>
            <span className="text-xl font-syne font-bold">ETH</span>
          </h2>
        </div>
        {!reserveMet && <h2>Reserve: {reserve}</h2>}
        {/* <h2 className="text-4xl font-space-mono">{ethBid}</h2> */}
        {/* <CurrencySelector eth={ethBid} usd={usdBid} callback={setBidType} /> */}
      </header>
      {/*  */}

      <div className="pt-10" />

      <ETHForm auction={auction} conversion={conversion} />
      {/* {bidType === "eth" ? (
        <ETHForm auction={auction} conversion={conversion} />
      ) : (
        <Elements stripe={stripe}>
          <USDForm auction={auction} />
        </Elements>
      )} */}

      {/* Modal  */}
    </div>
  );
};

const ETHForm = ({
  auction,
  conversion,
}: {
  auction: Auction | undefined;
  conversion?: number;
}) => {
  const { isConnected, publicAddress } = useMetamask();

  /**
   * To place an ETH bid, we do the following:
   * 1. Create an AuctionBid item in Firestore with State = 'pending'
   * 2. Create the eth transaction via Metamask
   * 3. The AuctionObserver will see the completed bid and update the Firestore documents, which we will have listeners on in order to update our views
   */
  const bidETH = useMutation(
    async (values: { bid: string; comment: string }) => {
      if (!auction) throw new Error("Missing Auction");
      if (!publicAddress) throw new Error("No metamask user found");

      // Convert the Ether value to wei base value. Always stored in the smallest unit (eth in wei, usd in pennies)
      const ethBid = ethers.utils.parseEther(`${values.bid}`).toString();

      // First, send the preBid request to our servers, get our observers to watch for Bids
      const auctionBidRequest: Omit<
        AuctionBid,
        | "id"
        | "byUserId"
        | "state"
        | "bidType"
        | "createdAt"
        | "updatedAt"
        | "photoURL"
      > = {
        byAddress: publicAddress,
        auctionContract: auction.auctionContract,
        tokenContract: auction.tokenContract,
        ethBid, // values.bid,
        usdBid: "0", //FIXME
        comment: values.comment,
      };
      console.log("AuctionBidRequest", auctionBidRequest);

      const { data: response } = await FirebaseClient.functions.httpsCallable(
        "bidWithETH"
      )(auctionBidRequest);
      console.log("Returned Bid Response", response);

      const result = await bidWithETH({
        auctionBid: response.bid,
        value: ethBid,
        comment: values.comment,
      });

      console.log("ethBid Result", result);
      return { auctionBid: auctionBidRequest, result };
    },
    {
      onSuccess: (data, action) => {},
    }
  );

  return (
    <Formik
      initialValues={{
        // FIXME: this value needs to come from the blockchain or a realtime hook into the auction firestore document
        bid: auction?.currentBid || "0",
        comment: "",
      }}
      onSubmit={(values, actions) => {
        if (isConnected) {
          bidETH.mutate(values);
        } else {
          // show modal
          // setIsOpen(true);
        }
      }}
    >
      {({ values }) => {
        let usdBid = "0.00";
        if (values.bid.match("[0-9]?.?[0-9]")) {
          usdBid = (parseFloat(values.bid) * (conversion || 0)).toFixed(2);
        }

        return (
          <Form>
            <div className="">
              <label className="sr-only" htmlFor="bid">
                Your Bid
              </label>
              <div className="flex items-center space-x-5 mt-1 p-2 bg-gray-100 relative rounded-full">
                <img className="" src="/ethereum-icon.svg" alt="" />

                <Field
                  type="text"
                  name="bid"
                  id="bid"
                  className="block w-full focus:ring-sky-500 focus:border-sky-500 pl-7 pr-12 border-gray-300 rounded-full font-syne-mono"
                  placeholder="0.00"
                  aria-describedby=""
                />
                {/* <span className="ml-4 text-2xl font-bold">Ξ</span> */}
              </div>
            </div>

            <Field
              type="text"
              name="comment"
              id="comment"
              className="my-2 p-2 w-full border-0 border-b-2  border-gray-200"
              placeholder="Add comment"
            />

            <div className="md:flex items-center justify-between">
              <div className="whitespace-nowrap font-syne-mono">
                ${usdBid} USD
              </div>

              <div className="mt-2 sm:flex sm:space-x-3 space-y-3 sm:space-y-0 items-center">
                <button
                  className="flex space-x-2 justify-center items-center px-8 py-2 w-full sm:w-auto bg-gray-200 hover:bg-gray-400 rounded-full"
                  type="reset"
                >
                  <XIcon className="h-4 w-4" />
                  <span>Cancel</span>
                </button>
                <button
                  className="flex justify-between items-center w-full sm:w-96 px-8 py-2 bg-indigo-600 hover:bg-sky-700 text-white text-left hover:bg-indigo-700 rounded-full"
                  type="submit"
                >
                  {bidETH.isLoading && <Spinner />}
                  Bid now
                  <ArrowRightIcon className="text-white h-5" />
                </button>
              </div>
            </div>

            {/* {errorMsg && (
                          <div className="text-red-500">{errorMsg}</div>
                        )} */}
          </Form>
        );
      }}
    </Formik>
  );
};
