import { CloudUploadIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

export const ImageUploader = () => {
  const { setFieldValue } = useFormikContext();

  const [img, setImg] = useState<any>();

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      const file = acceptedFiles[0];
      setFieldValue("file", file);
      console.log("file", file);

      // For Previewing
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        e.preventDefault();
        // console.log(e?.target?.result);

        setImg(e?.target?.result);
      };

      fileReader.readAsDataURL(file);
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <div>
      <label htmlFor="image-input" className="field-label">
        NFT Image
      </label>

      <div
        className="lg:h-96 w-full lgw-96 bg-gray-50 brder text-center overflow-hidden"
        {...getRootProps()}
      >
        <input id="image-input" {...getInputProps()} />
        {isDragActive && !!img ? (
          <p>Drop the file here ...</p>
        ) : (
          <p>Drag 'n' drop the file here, or click to select the file</p>
        )}
        <img
          className={`${
            !img ? "hidden" : "block"
          } mx-auto h96 lg:w-96 object-scale-down`}
          src={img}
          alt=""
        />
        {!img && (
          <CloudUploadIcon className="m-10 mx-auto h-24 text-indigo-200" />
        )}
      </div>
    </div>
  );
};
