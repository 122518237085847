import { ExternalLinkIcon } from "@heroicons/react/outline";
import { ethers } from "ethers";
import { useEffect, useState } from "react";

interface Contract {
  address: string;
  id?: string;
}

export const TokenHistoryPanel = ({
  token,
  auction,
}: {
  token: Contract;
  auction: Contract;
}) => {
  const [tokenEvents, setTokenEvents] = useState<any[]>([]);

  useEffect(() => {
    if (!token) return;
    if (!window.ethereum) return;
    const ABI = [
      "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
    ];

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(token.address, ABI, provider);
    // console.log("Contract", contract);

    const id = ethers.BigNumber.from(token?.id);

    const filter = contract.filters?.Transfer(null, null, id);
    contract.queryFilter(filter, 0, "latest").then((events) => {
      // console.log("Events", events);
      events = events.sort((a, b) => b.blockNumber - a.blockNumber);
      setTokenEvents(events);
    });
  }, [token]);

  return (
    <div className="p-0 bgwhite overflow-hidden">
      <header>
        <h1>Token History</h1>
      </header>

      <div className="mt-10" />

      <ul className="space-y-3">
        {tokenEvents?.map((event) => {
          return (
            <li
              key={event.transactionHash}
              className="flex justify-between p-4 border shadow rounded"
            >
              <h2 className="text-xl">Transfer</h2>
              <dl className="lg:flex items-center space-x-2">
                <div className="flex items-baseline space-x-2">
                  <dt className="text-sm font-bold">from</dt>
                  <dd className="text-sm font-space-mono">
                    {event.args?.from}
                  </dd>
                </div>
                <div className="flex items-baseline space-x-2">
                  <dt className="text-sm font-bold">to</dt>
                  <dd className="font-space-mono">{event.args?.to}</dd>
                </div>
              </dl>
              <div className="flex-grow-1">
                <a
                  href={`https://ropsten.etherscan.io/tx/${event?.transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ExternalLinkIcon className="h-5 text-gray-500" />
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
