import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bgwhite" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <div className="flex items-center">
              <img className="h-18" src="/opera.svg" alt="Opera cake icon" />
              <h1 className="text-xl md:text-4xl font-extrabold">
                OperaOmnia.app
              </h1>
            </div>
            <p className="text-gray-500 text-base">
              A smart way to smart contracts
            </p>
            <div className="flex space-x-6">
              {/* <a
                href="https://www.facebook.com/vertualabs"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Facebook</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a> */}

              {/* <a
                href="https://www.instagram.com/vertualabs/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Instagram</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a> */}

              <a
                href="https://twitter.com/OperaOmniaApp"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Twitter</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>

              {/* <a
                href="https://linkedin.com/company/vertualabs"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">LinkedIn</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 128.6 128.9"
                  aria-hidden="true"
                >
                  <g>
                    <g>
                      <path
                        style={{ fill: "currentColor" }}
                        d="M128.6,7.9c0,37.4,0,74.9,0,112.3c-0.1,0.1-0.2,0.2-0.2,0.3c-1.9,5.8-4.5,7.6-10.6,7.6c-31.2,0-62.5,0-93.7,0
			c-5,0-9.9,0-14.9,0c-5-0.1-9-4.1-9-9.2c0-36.6,0-73.2,0-109.8c0-4,2.5-7.4,6.3-8.8C7,0.3,7.3,0.2,7.7,0c37.8,0,75.7,0,113.5,0
			c0.2,0.1,0.3,0.2,0.5,0.3c3.6,1.1,5.8,3.4,6.7,7.1C128.4,7.6,128.5,7.7,128.6,7.9z M68.7,55.8c0-2.1,0-4.3,0-6.5
			c0-1-0.3-1.4-1.4-1.4c-5.3,0.1-10.6,0.1-15.8,0c-1.2,0-1.5,0.4-1.5,1.5c0,19.5,0,39,0,58.6c0,1.1,0.3,1.5,1.5,1.5
			c5.5,0,11,0,16.4,0c1.2,0,1.5-0.4,1.5-1.5c0-9.8-0.1-19.5,0-29.3c0-2.1,0.1-4.2,0.5-6.2c0.7-4.1,2.6-7.5,7-8.6
			c1.4-0.3,2.8-0.4,4.3-0.4c4.5,0.1,7.4,2.2,8.4,6.7c0.5,2.4,0.8,5,0.8,7.5c0.1,10.1,0,20.2,0,30.2c0,1.6,0,1.6,1.6,1.6
			c5.4,0,10.8,0,16.2,0c1.2,0,1.5-0.4,1.5-1.6c0-11.7,0.1-23.4-0.1-35c-0.1-3.9-0.6-7.8-1.5-11.6c-1.6-6.8-5.5-11.8-12.5-13.7
			c-2.4-0.7-4.9-0.9-7.4-1.1c-5.5-0.6-10.5,0.8-14.8,4.3C71.8,52.3,70.3,54.1,68.7,55.8z M38.5,78.7c0-9.8,0-19.5,0-29.3
			c0-1.2-0.3-1.6-1.5-1.6c-5.5,0.1-11,0.1-16.4,0c-1.2,0-1.4,0.4-1.4,1.5c0,19.5,0,39,0,58.5c0,1.2,0.4,1.6,1.6,1.6
			c5.4-0.1,10.8,0,16.2,0c1.6,0,1.6,0,1.6-1.7C38.5,98.1,38.5,88.4,38.5,78.7z M28.8,39.7c6.2,0,11.1-4.9,11.2-11
			c0-6.2-4.9-11.2-11.1-11.2c-6.2,0-11.1,4.9-11.1,11.2C17.7,34.8,22.6,39.7,28.8,39.7z"
                      />
                      <path
                        d="M7.7,0C7.3,0.2,7,0.3,6.6,0.5C2.8,1.8,0.3,5.2,0.3,9.2c0,36.6,0,73.2,0,109.8c0,5,3.9,9.1,9,9.2
			c5,0.1,9.9,0,14.9,0c31.2,0,62.5,0,93.7,0c6.1,0,8.7-1.8,10.6-7.6c0-0.1,0.1-0.2,0.2-0.3c0,2.9,0,5.8,0,8.6
			c-42.9,0-85.8,0-128.6,0C0,85.9,0,43,0,0C2.6,0,5.1,0,7.7,0z"
                      />
                      <path
                        d="M128.6,7.9c-0.1-0.2-0.2-0.3-0.3-0.5c-0.8-3.7-3.1-6-6.7-7.1c-0.2-0.1-0.3-0.2-0.5-0.3c2.5,0,4.9,0,7.4,0
			C128.6,2.6,128.6,5.3,128.6,7.9z"
                      />
                      <path
                        d="M68.7,55.8c1.6-1.7,3.1-3.5,4.9-5c4.3-3.5,9.3-4.9,14.8-4.3c2.5,0.3,5,0.4,7.4,1.1c7,1.9,10.9,6.9,12.5,13.7
			c0.9,3.8,1.4,7.7,1.5,11.6c0.2,11.7,0,23.4,0.1,35c0,1.2-0.3,1.6-1.5,1.6c-5.4-0.1-10.8,0-16.2,0c-1.6,0-1.6,0-1.6-1.6
			c0-10.1,0-20.2,0-30.2c0-2.5-0.2-5-0.8-7.5c-1-4.5-3.9-6.6-8.4-6.7c-1.4,0-2.9,0.1-4.3,0.4c-4.4,1.1-6.3,4.5-7,8.6
			c-0.4,2-0.5,4.1-0.5,6.2c0,9.8,0,19.5,0,29.3c0,1.1-0.3,1.5-1.5,1.5c-5.5-0.1-11,0-16.4,0c-1.2,0-1.5-0.4-1.5-1.5
			c0-19.5,0-39,0-58.6c0-1.1,0.3-1.5,1.5-1.5c5.3,0.1,10.6,0.1,15.8,0c1.1,0,1.4,0.3,1.4,1.4C68.7,51.5,68.7,53.7,68.7,55.8z"
                      />
                      <path
                        d="M38.5,78.7c0,9.7,0,19.4,0,29c0,1.7,0,1.7-1.6,1.7c-5.4,0-10.8,0-16.2,0c-1.2,0-1.6-0.3-1.6-1.6
			c0-19.5,0-39,0-58.5c0-1.1,0.3-1.5,1.4-1.5c5.5,0.1,11,0.1,16.4,0c1.3,0,1.5,0.4,1.5,1.6C38.4,59.2,38.5,69,38.5,78.7z"
                      />
                      <path
                        d="M28.8,39.7c-6.2,0-11.1-4.9-11.1-11.1c0-6.2,4.9-11.2,11.1-11.2c6.2,0,11.1,5,11.1,11.2
			C39.9,34.8,35,39.7,28.8,39.7z"
                      />
                    </g>
                  </g>
                </svg>
              </a> */}

              {/* <a
                href="mailto:support@vertualabs.com"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Email</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </a> */}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div></div>
              <div className="mt-12 md:mt-0">
                {/* <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Support
                </h3>
                <ul className="mt-4 space-y-4">
                  <Link
                    className="text-base text-gray-500 hover:text-gray-900"
                    to="/feedback"
                  >
                    Contact
                  </Link>
                </ul> */}
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                {/* <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      to="/about"
                      className="text-base text-gray-500 hover:text-gray-900"
                    >
                      About
                    </Link>
                  </li>

                  <li>
                    <a
                      href="http://vertualabs.substack.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-base text-gray-500 hover:text-gray-900"
                    >
                      Blog
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://linkedin.com/company/vertualabs"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-base text-gray-500 hover:text-gray-900"
                    >
                      Press
                    </a>
                  </li>
                </ul> */}
              </div>
              <div className="mt-12 md:mt-0">
                {/* <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <a
                      href="/"
                      className="text-base text-gray-500 hover:text-gray-900"
                    >
                      Privacy
                    </a>
                  </li>

                  <li>
                    <a
                      href="/"
                      className="text-base text-gray-500 hover:text-gray-900"
                    >
                      Terms
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; 2021 VertuaLabs, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
