import FirebaseClient from "../firebase_client";

export const signInWithMetamask = async (token: string) => {
  try {
    const userCredential = await FirebaseClient.auth.signInWithCustomToken(
      token
    );
    return userCredential;
  } catch (error: any) {
    var errorCode = error.code;
    var errorMessage = error.message;
    // ...
    console.error("Sign In With Metamask Error:", errorCode, errorMessage);
    throw new Error(`${errorCode}: ${errorMessage}`);
  }
};
