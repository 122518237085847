import { ethers } from "ethers";

import { AuctionBid } from "../../../types/firestore";
import FirebaseClient from "../../firebase/firebase_client";

const ABI = ["function bid(uint256,string) payable"];

export async function bidWithETH({
  auctionBid,
  value,
  comment,
}: {
  auctionBid: AuctionBid;
  value: string;
  comment: string;
}) {
  if (typeof window.ethereum !== "undefined") {
    // console.log(
    //   "Starting to place a bid...",
    //   auctionBid.auctionContract.address,
    //   value
    // );
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // console.log("Bid Provider: ", provider);
    const signer = provider.getSigner();
    // console.log("Bid signer", signer);

    const auctionContract = new ethers.Contract(
      auctionBid.auctionContract.address,
      ABI,
      signer
    );
    console.log("Found contract", auctionBid.auctionContract.address, signer);

    try {
      console.log("Starting Bid Transaction", value);
      const txOptions = {
        // value: ethers.utils.parseEther(`${value}`),
        value,
      };
      // console.log("bid value", txOptions.value);

      const transaction = await auctionContract.bid(
        auctionBid.auctionContract.id,
        comment,
        txOptions
      );
      console.log("Transaction", transaction);

      // Send update message to firestore to set state and add in the transaction hash
      FirebaseClient.functions
        .httpsCallable("bidWithETHTransactionStarted")({
          bidId: auctionBid.id,
          transactionHash: transaction.hash,
        })
        .then((response) => {
          // console.log("Response", response);
        });

      const result = await transaction.wait();
      console.log("Bid Transaction Result", result);
      return result;
    } catch (error) {
      console.error("Bid Error:", error);
    }
  } else {
    console.log("couldn't find window.ethereum");
  }
}
