import { Switch } from "@headlessui/react";
import { ethers } from "ethers";
import { Field, Form, Formik, FormikHelpers, FormikValues } from "formik";
import { useMutation, useQuery } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { useMetamask } from "../../contexts/metamask.context";
import { unpackSnapshot } from "../../utils/unpack_snapshot";
import { Spinner } from "../network_state/Spinner";

interface Values {
  name: string;
  metadataUri: string;
  rate: number;
  description: string;
  isPermissive: boolean;
  isPaused: boolean;
}

export const DeployAuctionContractForm = () => {
  const { publicAddress } = useMetamask();

  const { data: artifact } = useQuery(["defaultAuctionContract"], async () => {
    return FirebaseClient.db
      .collection("contracts")
      .doc("auction")
      .get()
      .then((snapshot) => {
        return unpackSnapshot({ snapshot });
      });
  });

  const deployContractMutation = useMutation(async (values: Values) => {
    if (!publicAddress) {
      console.error("No public address found", publicAddress);

      throw new Error("No public address found");
    }
    try {
      if (!window.ethereum) throw new Error("Metamask not found");

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const factory = ethers.ContractFactory.fromSolidity(
        JSON.parse(artifact?.solidityJSON),
        signer
      );
      const contract = await factory.deploy(
        values.name,
        values.rate,
        values.isPermissive,
        values.isPaused
      );

      console.log("Contract Deploying tx", contract, contract?.address);

      // we get the address immediately, we can then create a cloud function to create something in our system. this doesn't mean that the transaction has actually been mined yet. see docs <https://docs.ethers.io/v5/api/contract/contract-factory/>
      FirebaseClient.functions
        .httpsCallable("deployingContract")({
          contract: { address: contract.address },
          deployedByAddress: publicAddress,
          contractType: "auction",
          metadata: {
            name: values.name,
            description: values.description,
          },
          // TODO: add isPermissive
        })
        .then(console.log)
        .catch(console.error);

      await contract.deployed();
      console.log("Contract Deployed tx", contract, contract?.address);

      return contract;
    } catch (error) {
      console.error("DeployContract Error: ", error);
      return { hasError: true };
    }
  });
  return (
    <div className="relative bg-white border rounded-lg shadow-2xl overflow-hidden">
      <Formik
        initialValues={
          {
            name: "",
            description: "",
            rate: 5,
            isPaused: false,
            isPermissive: true,
          } as FormikValues
        }
        onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
          deployContractMutation.mutate({
            ...values,
          });
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="space-y-5">
            <div className="p-8 space-y-5">
              <h2 className="font-semibold">Auction Contract Form</h2>
              <p>
                This Contract allows you to create HighestBidder Auctions also
                known as English Auctions.
              </p>
              <p>
                You can view the source code for the contract on{" "}
                <a
                  className="underline"
                  href="https://github.com/VertuaLabs/ethereum-smart-contracts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Github
                </a>
                .
              </p>
              {/* CONTRACT NAME */}
              <div className="">
                <label
                  htmlFor="name"
                  className="block text-sm font-bold text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    className="field-text"
                    placeholder="Contract name (e.g., VertuaLabsGenericPermissive)"
                  />
                </div>
              </div>

              {/* DESCRIIPTION */}
              <div className="mt-3">
                <label
                  htmlFor="rate"
                  className="block text-sm font-bold text-gray-700"
                >
                  Admin Rate (where number is percentage; 5=5%)
                </label>
                <div className="mt-1">
                  <Field
                    type="number"
                    name="rate"
                    id="rate"
                    className="field-text"
                    placeholder="5"
                  />
                </div>
              </div>

              {/* DESCRIIPTION */}
              <div className="mt-3">
                <label
                  htmlFor="message"
                  className="block text-sm font-bold text-gray-700"
                >
                  Description
                </label>
                <div className="mt-1">
                  <Field
                    as="textarea"
                    type="textarea"
                    name="description"
                    id="description"
                    rows={4}
                    className="field-text"
                    placeholder="Description"
                  />
                </div>
              </div>

              {/* IS PERMISSIVE TOGGLE */}
              <div className="relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm">
                  <Switch.Group>
                    <div className="flex items-center">
                      <Switch.Label className="mr-4 font-bold text-gray-700">
                        Permissive
                      </Switch.Label>
                      <Switch
                        checked={values.isPermissive}
                        onChange={() =>
                          setFieldValue("isPermissive", !values.isPermissive)
                        }
                        className={`${
                          values.isPermissive ? "bg-indigo-600" : "bg-gray-200"
                        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      >
                        <span
                          className={`${
                            values.isPermissive
                              ? "translate-x-6"
                              : "translate-x-1"
                          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform ease-in-out duration-200`}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                  <p id="comments-description" className="text-gray-500">
                    If you set the contract to permissive, anyone can mint a
                    token. You can always change this setting later.
                  </p>
                </div>
              </div>
            </div>

            <div className="px-8 py-2 flex justify-end space-x-5 bg-gray-50 mt-2">
              <button
                type="reset"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md text-white bg-gray-300 shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <span>Reset</span>
              </button>
              <button
                type="submit"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {deployContractMutation.isLoading && <Spinner />}
                <span>Deploy Contract</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
