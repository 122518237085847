import { RadioGroup } from "@headlessui/react";
import { useState } from "react";

import { DeployAuctionContractForm } from "../../components/contracts/DeployAuctionContractForm";
import { DeployERC20FixedContractForm } from "../../components/contracts/DeployERC20FixedContractForm";
import { DeployERC20MinterContractForm } from "../../components/contracts/DeployERC20MinterContractForm";
import { DeployNFTContractForm } from "../../components/contracts/DeployNFTContractForm";

// TODO: dynamically grab these from the database
const contracts = [
  {
    id: "nft",
    name: "NFT Contract",
    description: "A Permissive NFT Contract to mint tokens",
    createdBy: "VertuaLabs",
    github: "https://github.com/VertuaLabs/ethereum-smart-contracts",
    price: "Free",
  },
  {
    id: "erc20-fixed",
    name: "ERC20 Fixed Supply Contract",
    description: "A Fixed Supply ERC20 Contract",
    createdBy: "VertuaLabs",
    github: "https://github.com/VertuaLabs/ethereum-smart-contracts",
    price: "Free",
  },
  {
    id: "erc20-minter",
    name: "ERC20 Minter Contract",
    description: "An ERC20 Contract with Minting and Roles",
    createdBy: "VertuaLabs",
    github: "https://github.com/VertuaLabs/ethereum-smart-contracts",
    price: "Free",
  },
  {
    id: "auction",
    name: "Auction Contract",
    description: "A Permissive Auction Contract",
    createdBy: "VertuaLabs",
    github: "https://github.com/VertuaLabs/ethereum-smart-contracts",
    price: "Free",
  },
  // {
  //   id: "dao",
  //   name: "DAO Contract",
  //   description: "A Standard DAO Contract",
  //   createdBy: "VertuaLabs",
  //   github: "https://github.com/VertuaLabs/ethereum-smart-contracts",
  //   price: "Free",
  // },
];

/**
 * TODO: add the selected contract as a query parameter to location
 *
 */
export const DeployContract = () => {
  const [selectedContract, setSelectedContract] = useState(contracts[0]);

  return (
    <div className="relative ">
      <div
        className="relative -mt-16 h-60 w-full bg-center bg-cover"
        style={{ backgroundImage: "url(/bg-deploy.png)" }}
      >
        <h1 className="pt-20 flex space-x-5 mx-auto max-w-7xl my-auto text-white">
          <span className="text-6xl font-extrabold">Deploy</span>
          <img className="h-20" src="/rocket.svg" alt="rocket icon" />
        </h1>
      </div>

      <div className="mt-10" />

      <div className="mx-auto max-w-7xl">
        <header className="p-2">
          <h1 className="text-3xl font-bold leading-5">
            Select a Contract to Deploy
          </h1>
          <h2 className="mt-5 text-xl font-medium leading-7">
            Deploy a contract to mint and admin your own NFTs, Tokens, Auctions,
            and much more coming soon!
          </h2>
        </header>
        <Contracts
          selected={selectedContract}
          setSelected={setSelectedContract}
        />
        <div className="mt-10" />
        {selectedContract.id === "nft" ? (
          <DeployNFTContractForm />
        ) : selectedContract.id === "auction" ? (
          <DeployAuctionContractForm />
        ) : selectedContract.id === "erc20-fixed" ? (
          <DeployERC20FixedContractForm />
        ) : selectedContract.id === "erc20-minter" ? (
          <DeployERC20MinterContractForm />
        ) : (
          <div className="text-2xl font-semibold">Coming Soon!</div>
        )}
      </div>
      <div className="py-36" />
    </div>
  );
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Contracts = (props: any) => {
  // const [selected, setSelected] = useState(contracts[0]);

  return (
    <RadioGroup value={props.selected} onChange={props.setSelected}>
      <RadioGroup.Label className="text-2xl font-bold">
        {/* Select a Contract to Deploy */}
      </RadioGroup.Label>
      <div className="pt-10" />
      <div className="relative grid grid-cols-2 md:grid-cols-5 gap-0">
        {contracts.map((contract) => (
          <RadioGroup.Option
            key={contract.id}
            value={contract}
            className={({ active, checked }) =>
              classNames(
                active || checked
                  ? "bg-white ring-1 ring-offset-2 ringindigo-600 shadow-2xl"
                  : "bg-gray-50",
                "relative h-60 w-48 elative block rounded-lg border border-gray-300 shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
              )
            }
          >
            {({ checked }) => (
              <div className="flex flex-col justify-between text-center">
                <div className="flex flex-col justify-between text-center">
                  <div className="mx-auto">
                    <img
                      className="h-12 w-12 bg-whiterounded-full"
                      src="/smart-contract.svg"
                      alt="Smart contract icon"
                    />
                  </div>
                  <RadioGroup.Label
                    as="h2"
                    className="text-base text-gray-900 font-bold"
                  >
                    {contract.name}
                  </RadioGroup.Label>

                  <RadioGroup.Description
                    as="div"
                    className="flex-grow text-gray-500"
                  >
                    <p className="text-sm">{contract.description}</p>
                  </RadioGroup.Description>
                </div>

                <button
                  className={`${
                    checked
                      ? "borderindigo-600 bg-indigo-600 text-white"
                      : " text-indigo-600"
                  }
                    border text-sm rounded-2xl leading-6`}
                >
                  {checked ? "Selected" : "Select"}{" "}
                </button>

                <div
                  className={`${
                    checked
                      ? "border-indigo-600 shadow-2xl"
                      : "border-transparent"
                  } absolute -inset-px rounded-lg border-2 pointer-events-none`}
                  aria-hidden="true"
                />
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
