import React, { useMemo } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";

import { unpackSnapshotArray } from "../../utils/unpack_snapshot_array";
import FirebaseClient from "./firebase_client";

export function useOnSnapshotWhereEqual<Data>(
  path: string,
  where: string,
  to: string,
  useQueryOptions: UseQueryOptions<Data> = {}
) {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [path, to], [path, to]);
  // console.log("QueryKey:", queryKey);

  React.useEffect(() => {
    const unsubscribe = FirebaseClient.db
      .collection(path)
      .where(where, "==", to)
      // .orderBy("createdAt")
      .onSnapshot({
        next: (snapshots) => {
          let data = unpackSnapshotArray({ snapshots });
          // console.log("Next", data);

          data = data.sort((a, b) => b.createdAt - a.createdAt);

          queryClient.setQueryData(queryKey, data);
        },
        error: (err) => {
          console.error("Error: ", err);
        },
      });

    return () => unsubscribe();
  }, [queryClient, queryKey, path, to, where]);

  return useQuery<Data, Error>(queryKey, () => new Promise<Data>(() => {}), {
    enabled: !!path && !!where && !!to,
  });
}
