import { ethers } from "ethers";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { useMetamask } from "../../contexts/metamask.context";
import { unpackSnapshot } from "../../utils/unpack_snapshot";
import { unpackSnapshotArray } from "../../utils/unpack_snapshot_array";
import { ImageUploader } from "../ImageUploader";
import { Spinner } from "../network_state/Spinner";

interface Values {
  name: string;
  description: string;
  attributes: string[];
  file: File | undefined;
  image: string;
}

const validate = (values: Values) => {
  const errors = {} as any;
  if (!values.name) {
    errors.name = "Required";
  }
};

/**
 *
 * TODO: this should dynamically accept any NFT contract
 */
export const MintNFTForm = ({ contractId }: { contractId: any }) => {
  // console.log("Contract Id", contractId);

  const [nftRequests, setNFTRequests] = useState<any[]>([]);

  const { publicAddress } = useMetamask();

  // Get the Selected NFT Contract's address
  const { data: selectedNFTContract } = useQuery(
    ["contracts", { contractId }],
    async () => {
      return FirebaseClient.db
        .collection("contracts")
        .doc(contractId)
        .get()
        .then((snapshot) => {
          return unpackSnapshot({ snapshot });
        });
    }
  );
  // console.log("Selected NFT Contract", selectedNFTContract);

  // Get the base NFT contract. For now we are only deploying versions of our base contracts. We'll grab that contract to get the ABI
  const { data: baseNFTContract } = useQuery(
    ["defaultContracts", { id: "nft" }],
    async () => {
      return FirebaseClient.db
        .collection("contracts")
        .doc("nft")
        .get()
        .then((snapshot) => {
          return unpackSnapshot({ snapshot });
        });
    }
  );
  // console.log("Base NFT Contract", baseNFTContract);

  useEffect(() => {
    if (!publicAddress) {
      return;
    }
    const unsubscribe = FirebaseClient.db
      .collection("tokens")
      .where("requestedByAddress", "==", publicAddress)
      .where("state", "!=", "minted")
      .onSnapshot({
        next: (snapshots) => {
          let data = unpackSnapshotArray({ snapshots });
          // console.log("Next", data);
          data = data.sort((a, b) => b.createdAt - a.createdAt);
          setNFTRequests(data);
        },
        error: (err) => {
          console.error("Error: ", err);
        },
      });

    return () => unsubscribe();
  }, [publicAddress]);

  const mutation = useMutation(
    async (values: Values) => {
      if (!values.file) {
        alert("Please select an image");
        return;
      }
      if (!publicAddress) {
        alert("Please connect Metamask");
        return;
      }
      if (!baseNFTContract) {
        throw new Error("Missing Base NFT Contract");
      }
      if (!selectedNFTContract) {
        throw new Error("Missing Selected NFT Contract");
      }
      const { attributes, description, name } = values;
      // Upload the NFT to Firebase Storage and get the uri
      const storageRef = FirebaseClient.storage.ref();
      // We use uid to upload the image. We'll transfer the image to the public nft folder in the server
      // TODO: allow for no file and for arbitrary types of files
      const ref = storageRef.child(
        `users/${FirebaseClient.auth?.currentUser?.uid}/${values.file.name}`
      );
      const snapshot = await ref.put(values.file);

      const uri = await snapshot.ref.getDownloadURL();

      // Create a TokenRequest so that we can track interactions
      const { data: response } = await FirebaseClient.functions.httpsCallable(
        "mintToken"
      )({
        requestedByAddress: publicAddress,
        contract: { address: selectedNFTContract.contract.address },
        metadata: {
          name,
          description,
          attributes,
          imageUri: uri,
        },
        image: { filename: values.file?.name },
      });
      // console.log("Firestore Token", response);

      // Step 2. User creates the token
      // console.log("Start safeMint Transaction");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const contract = new ethers.Contract(
        selectedNFTContract?.contract.address,
        baseNFTContract?.abi,
        signer
      );
      const transaction = await contract["safeMint(uint256)"](
        response.token.contract.id
      );
      const result = await transaction.wait();
      // console.log("safeMint Transaction Completed", result);
      return result;
      //
    }
    // {
    //   onSuccess: (data) => {},
    // }
  );

  const header = `Mint a ${selectedNFTContract?.metadata?.name} (${selectedNFTContract?.metadata.symbol}) Token`;

  return (
    <div className="relative bg-white border rounded-lg shadow-2xl overflow-hidden">
      <header className="px-8 pt-8 space-y-2">
        <h1 className="text-2xl font-semibold" style={{ lineHeight: "120%" }}>
          NFT Details
        </h1>
        {/* TODO: paragraph needs to depend on contract. */}
        <p className="text-sm text-gray-500">
          This form allows you to mint a new NFT. There is no fee beyond the
          standard gas fee.
        </p>
      </header>

      <div className="pt-10" />

      <Formik
        initialValues={{
          name: "",
          description: "",
          attributes: [],
          image: "",
          file: undefined,
        }}
        validate={validate}
        onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
          mutation.mutate(values);
        }}
      >
        {({ errors }) => {
          return (
            <Form className="relative">
              <div className="px-8 pb-8">
                {/* NAME */}
                <div className="mt-3">
                  <label htmlFor="name" className="field-label">
                    Name
                  </label>
                  <div className="mt-1">
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      required
                      className="field-text"
                      placeholder="Full name"
                    />
                  </div>
                  {errors.name ? <div>{errors.name}</div> : null}
                </div>

                {/* DESCRIPTION */}
                <div className="mt-3">
                  <label htmlFor="message" className="field-label">
                    Description
                  </label>
                  <div className="mt-1">
                    <Field
                      as="textarea"
                      type="textarea"
                      name="description"
                      id="description"
                      rows={2}
                      className="field-text"
                      placeholder="Description"
                    />
                  </div>
                </div>

                {/* ATTRIBUTES */}
                <div className="mt-3">
                  <label htmlFor="attributes" className="field-label">
                    Attributes
                  </label>
                  <div className="mt-1">
                    <Field
                      type="text"
                      name="attributes"
                      id="attributes"
                      className="field-text"
                      placeholder="Attributes"
                    />
                  </div>
                </div>
                <div className="mt-10" />
                <ImageUploader />
              </div>

              <div className="px-8 py-2 flex justify-end space-x-5 bg-gray-50 mt-2">
                <button type="reset" className="btn-reset">
                  <span>Reset</span>
                </button>

                <button type="submit" className="btn-submit">
                  {mutation.isLoading && <Spinner />}
                  Mint NFT
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
