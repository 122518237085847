import { Contract } from "../../types/firestore";

export const ContractHeader = ({
  contract,
  header,
  subheader,
}: {
  contract: Contract;
  header: string;
  subheader: string;
}) => {
  return (
    <header className="grid grid-cols-1 lg:grid-cols-12 grid-flow-row-dense gap-8">
      <div className="hidden lg:block lg:col-span-4 text-center">
        <img
          className="mx-auto h-48 md:h-96 object-cover"
          src={contract?.metadata?.imageUri || "/smart-contract.svg"}
          alt="smart contract icon"
        />
      </div>
      {/*  */}
      <div className="lg:col-span-8">
        <header className="flex space-x-5 lg:space-x-0 ">
          <div className="block lg:hidden text-center">
            <img
              className="mx-auto w-full object-cover"
              src={contract?.metadata?.imageUri || "/smart-contract.svg"}
              alt="smart contract icon"
            />
          </div>
          <div className="space-y-5">
            <div className="flex items-center space-x-5 text-indigo-600">
              <h3 className="text-xl font-bold">Contract</h3>
              <a
                className="inline-flex items-center text-gray-500 text-xs underline"
                href={`https://ropsten.etherscan.io/address/${contract?.contract?.address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to contract on Etherscan
              </a>
            </div>

            <h1 className="text-xl md:text-4xl font-bold">{header}</h1>
            <h2 className="text-sm text-gray-700 font-semibold">{subheader}</h2>
          </div>
        </header>{" "}
        <div className="mt-4" />
        <div>
          <h2 className="text-sm font-semibold">Description</h2>
          <p className="mt-1 text-sm">{contract?.metadata?.description}</p>
        </div>
        <div className="mt-5" />
        <h2 className="text-sm font-semibold">Details</h2>
        <dl className="mt-2 grid grid-cols-1 md:grid-cols-5 place-items-left gap-2">
          <dt className="text-xs font-semibold">Owned by Address</dt>
          <dd className="md:col-span-4 text-sm truncate">
            {contract?.ownedByAddress}
          </dd>

          <dt className="text-xs font-semibold">Contract State</dt>
          <dd className="md:col-span-4 text-sm truncate">{contract?.state}</dd>

          <dt className="text-xs font-semibold">Deployed by Address</dt>
          <dd className="md:col-span-4 text-sm truncate">
            {contract?.deployedByAddress}
          </dd>

          <dt className="text-xs font-semibold">Deployed at</dt>
          <dd className="md:col-span-4 text-sm truncate">
            {new Date(contract?.createdAt).toLocaleString()}
          </dd>
        </dl>
      </div>
    </header>
  );
};
