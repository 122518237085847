import { SearchIcon } from "@heroicons/react/outline";
import algoliasearch from "algoliasearch";
import { debounce } from "lodash";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Spinner } from "../../components/network_state/Spinner";

const APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID as string;
const SEARCH_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_KEY as string;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useSearchQuery() {
  return new URLSearchParams(useLocation().search);
}

export const SearchPage = () => {
  const queryParams = useSearchQuery();
  const query = queryParams.get("q");
  console.log(queryParams, query);

  const history = useHistory();

  // const [query, setQuery] = useState("");
  const handleChange = debounce((value: string) => {
    history.push(`/search?q=${value}`);
  }, 500);

  const { data: results, isLoading } = useQuery(
    ["search", { query }],
    async () => {
      if (!query) return;
      const client = algoliasearch(APP_ID, SEARCH_KEY);
      const index = client.initIndex("dev_vertuaverse");
      return index.search(query).then((response) => {
        console.log("ALGOLIA RESPONSE", response);
        return response.hits as any[];
      });
    },
    { enabled: !!query }
  );

  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Search Page</title>
      </Helmet>
      <div
        className="relative -mt-16 h-60 w-full bg-center bg-cover"
        style={{ backgroundImage: "url(/bg-create.png)" }}
      >
        <h1 className="pt-20 flex space-x-5 mx-auto max-w-7xl my-auto text-white">
          <span className="text-6xl font-extrabold">Search</span>
          <SearchIcon className="h-20" />
          {/* <img className="h-20" src="/crane.svg" alt="crane icon" /> */}
        </h1>
      </div>

      <div className="mt-10" />

      <main className="p-5 lg:p-0 mx-auto max-w-7xl">
        <div className="pt-10" />
        <section className="p-5 pb-20 bordershadowrounded">
          <div className={`px-4 py-1 w-full rounded-full`}>
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <svg
                  className="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                id="search"
                name="search"
                className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                placeholder="Search"
                type="search"
                // onFocus={(e) => setMode("query")}
                // TODO: debounce
                onChange={(e) => {
                  handleChange(e.currentTarget.value);
                  // setQuery(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          <div className="mt-10" />
          {/* Results Outlet */}
          {!!results && (
            <>
              <h2 className="mb-5 text-2xl font-bold">Results</h2>
              <div className="flex justify-center">
                {isLoading && <Spinner color="text-pink-500" />}
              </div>
              <ul className="space-y-5">
                {results?.map((result) => {
                  console.log(result);

                  const to = `/${result.algoliaType.toLowerCase()}s/${
                    result.id
                  }`;
                  const image =
                    result?.metadata?.imageUri ||
                    result?.tokenMetadata?.imageUri ||
                    result?.imageURL ||
                    "/penrose.svg";
                  const header =
                    result?.metadata?.name ||
                    result?.tokenMetadata?.name ||
                    result?.name ||
                    " ";
                  const description =
                    result?.metadata?.description ||
                    result?.tokenMetadata?.description ||
                    result?.description ||
                    " ";
                  let attributes =
                    result?.metadata?.attributes ||
                    result?.tokenMetadata?.attributes ||
                    result?.attributes ||
                    " ";
                  if (Array.isArray(attributes)) {
                    attributes = attributes.join(", ");
                  }

                  return (
                    <Link
                      className="flex p-2 h-28 space-x-5 border shadow rounded overflow-hidden hover:shadow-2xl"
                      to={to}
                      key={result.id}
                    >
                      <img
                        className="h-24 w-24 object-scale-down"
                        src={image}
                        alt={result?.metadata?.name}
                      />
                      <div className="max-w-xl space-y-2">
                        <header className="space-y-2">
                          <h1 className="font-bold text-xl underline">
                            {header}
                          </h1>
                          <div className="flex items-center space-x-2">
                            <h2 className="text-xs font-semibold capitalize">
                              {result?.algoliaType}
                            </h2>
                            <p className="text-xs font-bold truncate">
                              {attributes}
                            </p>
                          </div>
                        </header>

                        <p className="text-sm truncate">{description}</p>
                      </div>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
        </section>
      </main>
    </div>
  );
};
