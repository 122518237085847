import { Link } from "react-router-dom";

import { useMetamask } from "../contexts/metamask.context";

export const NetworkStateBanner = () => {
  const { chainId, isConnected } = useMetamask();
  // console.log("chainId", chainId);

  const text = !isConnected
    ? "Pleace Connect Your Wallet for Full Functionality"
    : chainId !== "3"
    ? "Please Connect to Ropsten Testnet"
    : null;

  if (!!isConnected && chainId === "0x3") {
    return null;
  }

  return (
    <div className="fixed w-full z-20 bg-indigo-600">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-white">
            <span className="md:hidden">Mobile Support Coming Soon!</span>
            <span className="hidden md:inline">{text}</span>
            {!isConnected && (
              <span className="block sm:ml-2 sm:inline-block">
                <Link
                  to="/connect"
                  className="hidden md:block text-white font-bold underline"
                >
                  Connect<span aria-hidden="true">&rarr;</span>
                </Link>
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
