import { CheckIcon } from "@heroicons/react/outline";
import { ethers } from "ethers";
import { useState } from "react";
import { useMutation } from "react-query";

import { startAuction } from "../../api/metamask/auctions/start_auction";
import { useAuctions } from "../../api/metamask/auctions/use_auctions";
import { useContractQuery } from "../../api/metamask/use_contract_query";
import { useMetamask } from "../../contexts/metamask.context";
import { Spinner } from "../network_state/Spinner";
import { TransferFromForm } from "../tokens/TransferFromForm";

export const AuctionCheckList = ({
  auctionContract,
  tokenContract,
}: {
  auctionContract?: {
    address: string;
    id: string;
  };
  tokenContract?: {
    address: string;
    id: string;
  };
}) => {
  // console.log(auctionContract, tokenContract);

  const [showPanel, setShowPanel] = useState("");

  const { publicAddress } = useMetamask();

  // Check if token transferred to auction

  const { data: tokenOwner } = useContractQuery({
    address: tokenContract?.address,
    args: [tokenContract?.id],
    contractId: "nft",
    functionSelector: "ownerOf(uint256)",
  });

  const auctionOwned = tokenOwner === auctionContract?.address;
  // console.log(
  //   "TokenOwner",
  //   tokenOwner,
  //   auctionContract?.address,
  //   `${auctionOwned}`
  // );

  const { data: auction } = useAuctions({
    address: auctionContract?.address,
    id: auctionContract?.id,
  });
  const reserve = auction?.reserve;
  const ethReserve = !!reserve ? ethers.utils.formatEther(reserve) : "0";

  const startAuctionMutation = useMutation(async () => {
    if (!auctionContract) return;
    return await startAuction({
      address: auctionContract?.address,
      id: auctionContract?.id,
    });
  });

  return (
    <div>
      <header>
        <h1 className="text-2xl font-bold leading-5">Auction Checklist</h1>
        <p className="text-sm">
          These actions need to be done in order for the auction to start.
        </p>
      </header>

      <div className="my-4" />

      <div className="md:grid grid-cols-3 gap-4">
        <div className="flex flex-col p-5 border rounded shadow">
          <h1 className="text-5xl text-indigo-600 font-bold">1</h1>
          <h2 className="text-xl font-semibold">Transfer Token</h2>
          <p className="text-sm text-gray-500">
            The token needs to be transferred to the auction. You and only you
            can withdraw the token from the auction unless there is a winner.
          </p>

          <div className="flex-grow pt-10" />

          {!auctionOwned && (
            <button
              className="flex w-full items-center justify-center px-8 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded"
              onClick={() =>
                showPanel === "transfer"
                  ? setShowPanel("")
                  : setShowPanel("transfer")
              }
            >
              {startAuctionMutation.isLoading && <Spinner />}
              <span className="ml-2 text-sm font-semibold">
                Transfer Token to Auction
              </span>
            </button>
          )}
          {!!auctionOwned && (
            <p className="flex items-center">
              <CheckIcon className="h-8 text-green-500" /> Completed
            </p>
          )}
          {/* <p>Transfer token {`${auctionOwned}`}</p> */}
        </div>

        <div className="flex flex-col p-5 border rounded shadow">
          <h1 className="text-5xl text-indigo-600 font-bold">2</h1>
          <h2 className="text-xl font-semibold">Confirm Reserve and Time</h2>
          <p className="text-sm text-gray-500">
            Make sure the reserve and countdown timer are what you want before
            starting the auction. These values cannot be changed once the
            auction start.
          </p>
          <dl className="mt-5">
            <div className="flex items-center space-x-2">
              <dt className="text-xs font-bold">Current Reserve</dt>
              <dd className="flex items-center space-x-2 text-sm">
                <img
                  className="h-5 w-5"
                  src="/ethereum-icon.svg"
                  alt="Ethereum icon"
                />
                <span>{`${ethReserve}`} ETH</span>
              </dd>
            </div>
            <div className="flex items-center space-x-2">
              <dt className="text-xs font-bold">Current Countdown Duration:</dt>
              <dd className="text-sm">24 hours</dd>
            </div>
          </dl>
          <div className="flex-grow py-4" />
          <button
            className="flex items-center w-full justify-center px-8 py-2 bg-indigo-600 hover:bg-indigo-600 text-white rounded"
            disabled
            onClick={() =>
              showPanel === "edit" ? setShowPanel("") : setShowPanel("edit")
            }
          >
            {/* {startAuctionMutation.isLoading && <Spinner />} */}
            <span className="ml-2 text-sm font-semibold">Edit coming soon</span>
          </button>
        </div>

        <div className="flex flex-col p-5 border rounded shadow">
          <h1 className="text-5xl text-indigo-600 font-bold">3</h1>
          <h2 className="text-xl font-semibold">Start Auction</h2>
          <p className="text-sm text-gray-500">
            When you are ready, go ahead and start the auction. As soon as your
            reserve price has been met your count down timer will be triggered.
            You can cancel the auction as any time until the auction has
            declared a winner.
          </p>

          <div className="flex-grow py-4" />
          <button
            className="flex items-center w-full justify-center px-8 py-2 bg-indigo-600 hover:bg-indigo-600 text-white rounded"
            onClick={() => startAuctionMutation.mutate()}
          >
            {startAuctionMutation.isLoading && <Spinner />}
            <span className="ml-2 text-sm font-semibold">Start Auction</span>
          </button>
        </div>
      </div>

      {showPanel === "transfer" && publicAddress && (
        <>
          <div className="pt-10" />

          <TransferFromForm
            publicAddress={publicAddress}
            tokenContract={tokenContract}
            auctionContract={auctionContract}
          />
        </>
      )}

      {showPanel === "edit" && publicAddress && (
        <>
          <div className="pt-10" />
          <p>Coming soon!</p>
        </>
      )}
    </div>
  );
};
