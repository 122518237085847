import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { FireIcon, LinkIcon } from "@heroicons/react/solid";
import { ethers } from "ethers";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import FirebaseClient from "../../api/firebase/firebase_client";
import { useAuctionName } from "../../api/metamask/auctions/use_auction_name";
import { useAuctions } from "../../api/metamask/auctions/use_auctions";
import { useContractQuery } from "../../api/metamask/use_contract_query";
import { queryClient } from "../../api/react_query/react_query";
import { AuctionDetailsDisclosure } from "../../components/auctions/AuctionDetailsDisclosure";
import { AuctionOwnerPanel } from "../../components/auctions/AuctionOwnerPanel";
import { AuctionPanel } from "../../components/auctions/AuctionPanel";
import { Countdown } from "../../components/auctions/CountdownPanel";
import { CreateAuctionForm } from "../../components/auctions/CreateAuctionForm";
import { RecentBidsPanel } from "../../components/auctions/RecentBidsPanel";
import { Spinner } from "../../components/network_state/Spinner";
import { TokenStateBanner } from "../../components/tokens/AuctionStateBanner";
import { TokenDetailsDisclosure } from "../../components/tokens/TokenDetailsDisclosure";
import { TokenHistoryPanel } from "../../components/tokens/TokenHistoryPanel";
import { TransferFromForm } from "../../components/tokens/TransferFromForm";
import { useMetamask } from "../../contexts/metamask.context";
import { unpackSnapshot } from "../../utils/unpack_snapshot";

/**
 * A basic page for displaying information about Tokens (ERC20, ERC721) and admin functionality for the token owner.
 *
 * TODO: on page load, call to the blockchain to see if the token has been minted. We might have missed it. There may also be multiple copies of the attempted token in our firestore system because the user didn't go through with the minting process. We should give the user an option to delete those 'to be minted' tokens.
 *
 */
export const TokenPage = () => {
  // TODO: should be able to use token.contract.id as well as fs id
  const { id } = useParams<any>();
  const [showPanel, setShowPanel] = useState("");
  const history = useHistory();

  const [ownerActions, setOwnerActions] = useState("closed");

  const uid = FirebaseClient.auth.currentUser?.uid || "";

  const { publicAddress } = useMetamask();

  const { data } = useQuery(
    ["tokens", { id }],
    async () => {
      return FirebaseClient.db
        .collection("/tokens")
        .doc(id)
        .get()
        .then((snapshot) => {
          return unpackSnapshot({ snapshot });
        });
    },
    { enabled: !!id }
  );
  // console.log("data", data);

  const { data: auction } = useQuery(
    ["auctions", { tokenContractId: data?.contract.id }],
    async () => {
      // FIXME: there is a 1-to-N of token to auctions b/c the token can be auctioned multiple times. Current code just grabs one auction of out all the possible auctions that could fit the query
      return FirebaseClient.db
        .collection("/auctions")
        .where("tokenContract.id", "==", data?.contract.id)
        .limit(1)
        .get()
        .then((snapshots) => {
          // console.log("snap", snapshots);
          if (snapshots.size > 0) {
            const result = unpackSnapshot({ snapshot: snapshots.docs[0] });
            // console.log(result);
            return result;
          }
          return undefined;
        });
    },
    { enabled: !!data?.contract.id }
  );
  // console.log("auction", auction, data?.contract);
  const { data: tokenOwner } = useContractQuery({
    address: data?.contract.address,
    args: [data?.contract.id],
    contractId: "nft",
    functionSelector: "ownerOf(uint256)",
  });

  // console.log("tokenOwner", tokenOwner);

  const { data: auctionStruct } = useAuctions({
    address: auction?.auctionContract?.address,
    id: auction?.auctionContract?.id,
  });

  const { data: auctionContractName } = useAuctionName({
    address: auction?.auctionContract?.address,
  });

  const auctionOwner = auctionStruct?.seller;
  const auctionState = auctionStruct?.state;
  const auctionIsRunning =
    auctionState == 2 || auctionState == 3 || data?.state === "running";

  // const { data: contractName } = useName(data?.contract?.address);
  // const { data: contractSymbol } = useSymbol(data?.contract?.address);

  const deleteTokenMutation = useMutation(
    async () => {
      return FirebaseClient.functions.httpsCallable("deleteToBeMintedToken")({
        tokenId: data?.id,
      });
    },
    {
      onSuccess: ({ data }) => {
        // TODO: some sort of modal and then return to dashboard
        const queryKey = ["tokens", { id }];
        queryClient.removeQueries(queryKey, { exact: true });
        history.goBack();
      },
    }
  );

  // TODO: once the general useContractMutation is finished, use that here too
  const mintTokenMutation = useMutation(async () => {
    const snapshot = await FirebaseClient.db
      .collection("contracts")
      .doc("nft")
      .get();
    const baseNFTContract = unpackSnapshot({ snapshot });

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    if (!publicAddress) {
      alert("Please connect metamask");
      return;
    }
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      data?.contract.address,
      baseNFTContract?.abi,
      signer
    );
    const transaction = await contract["safeMint(uint256)"](data.contract.id);
    const result = await transaction.wait();
    return result;
  });

  const reIndexMutation = useMutation(
    async () => {
      return FirebaseClient.functions.httpsCallable("reIndexToken")({
        contract: data?.contract,
        tokenId: data?.id,
      });
    },
    {
      onSuccess: ({ data }) => {
        // console.log('ReIndex Response:' data);
        const queryKey = ["tokens", { id }];
        queryClient.setQueryData(queryKey, () => data.token);
      },
    }
  );

  // const hasMintIndexError = true;
  const hasMintIndexError = data?.state === "to be minted";

  let owner = !!publicAddress && publicAddress === tokenOwner;

  // Determine if this has been minted and we missed it or if it wasn't minted for some reason
  if (hasMintIndexError) {
    owner = publicAddress === data?.ownedByAddress;
  }
  const minted = !tokenOwner;

  // FIXME: shim for attributes sometimes being a string and sometimes being an string[]
  const attributes =
    typeof data?.metadata?.attributes === "string"
      ? data?.metadata.attributes
      : data?.metadata.attributes.join(", ");

  const isAuctionRunning = auction?.state == 2 || auction?.state == 3;

  return (
    <div className="">
      <Helmet>
        <title>{`${data?.metadata?.name}`} Token Page</title>
      </Helmet>
      {/* {owner && (
        <TokenStateBanner auction={auction} auctionState={auctionState} />
      )} */}
      {/* <TokenStateBanner auction={auction} auctionState={auctionState} /> */}
      <main className="p-5 lg:p-0 mx-auto max-w-7xl oerflow-hidden">
        <section className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          <div className="lg:col-span-4">
            <img
              className="h-56 md:h-96 w-56  md:w-96 object-scale-down"
              src={data?.metadata?.imageUri || "/penrose.svg"}
              alt=""
            />
            <div className="hidden lg:block mt-2">
              <TokenDetailsDisclosure token={data} />
              {auction && <AuctionDetailsDisclosure auction={auction} />}
            </div>
          </div>

          <div className="lg:col-span-8">
            <header className="space-y-5">
              {auction && (
                <p className="text-indigo-600 font-bold">At Auction</p>
              )}
              <h1 className="text-xl md:text-4xl font-bold">
                {data?.metadata?.name || "no name"}
              </h1>

              <div>
                <h2 className="text-sm font-semibold">Description</h2>
                <p>{data?.metadata?.description}</p>
              </div>

              <div>
                <h2 className="text-sm font-semibold">Attributes</h2>
                <p>{attributes}</p>
              </div>
            </header>
            <div className="block lg:hidden mt-2">
              <TokenDetailsDisclosure token={data} />
            </div>
          </div>
        </section>

        <div className="mt-10" />
        {/* If being Auctioned, display AuctionPanel */}
        {/* If not being Auctioned and owner if viewing, display Actions Panel */}
        {hasMintIndexError && owner && (
          <section className="p-5 border rounded shadow">
            <header>
              <h1 className="text-2xl text-red-500 font-bold">
                Minting and Indexing Errors
              </h1>
              <p className="text-sm">
                Sometimes we may miss something that has been minted (wip).
              </p>
              <p className="text-sm">
                These controls allow us to fix those misses.
              </p>
            </header>

            {/* REINDEX */}
            <div className="mt-10" />
            {!minted && data?.state === "to be minted" && (
              <div className="">
                <h3 className="text-xl font-medium">Token Index Failed</h3>
                <p className="text-sm">
                  Token was correctly minted but not ingested into the system.
                  Please re-index the token by pressing 'Re-index' below.
                </p>
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => reIndexMutation.mutate()}
                >
                  {reIndexMutation.isLoading && <Spinner />}
                  <span>Re-index</span>
                </button>
              </div>
            )}

            {/* MINT || DELETE */}
            {!!minted && data?.state === "to be minted" && (
              <div>
                <h3 className="text-xl font-normal">Token Minting Failed</h3>
                <p className="text-sm">
                  Your Token was not minted. This may have happened for several
                  reasons. All the information that you wanted to be minted was
                  stored.
                  <br />
                  If you still want to Mint this token, please press 'Mint
                  Token'. You may also keep this to be Minted token
                  indefinitely. Finally, you have the option to delete this data
                  by pressing 'Delete PreToken'.
                </p>
                <div className="mt-5 space-x-5">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => mintTokenMutation.mutate()}
                  >
                    {mintTokenMutation.isLoading && <Spinner />}
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>Mint Token</span>
                  </button>
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-400 shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => deleteTokenMutation.mutate()}
                  >
                    {deleteTokenMutation.isLoading && <Spinner />}
                    <TrashIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>Delete PreToken</span>
                  </button>
                </div>
              </div>
            )}
          </section>
        )}

        {/* OWNER ACTION PANEL */}
        {!!owner && !auction && (
          <>
            <div className="my-10" />

            <section className="px-5 py-10 border shadow">
              <header className="">
                <h2 className="text-2xl font-bold leading-5">Owner Actions</h2>
                <p className="text-sm">
                  Below are the actions that you - the owner - can do with this
                  token.
                </p>
              </header>

              <div className="mt-5" />
              <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-10">
                {/* TODO: if token is being auctioned, show 'Go to Auction to control the auction option' and hide other controls that can't be used */}

                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() =>
                    showPanel === "auction"
                      ? setShowPanel("")
                      : setShowPanel("auction")
                  }
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  <span>Auction Token</span>
                </button>

                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() =>
                    showPanel === "transfer"
                      ? setShowPanel("")
                      : setShowPanel("transfer")
                  }
                >
                  <LinkIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  <span>Transfer Token</span>
                </button>
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-400 shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  onClick={() =>
                    showPanel === "burn"
                      ? setShowPanel("")
                      : setShowPanel("burn")
                  }
                >
                  <FireIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  <span>Burn Token</span>
                </button>
              </div>
            </section>
          </>
        )}

        {/* CONDITIONAL RENDER OUTLETS */}
        {showPanel === "auction" && !!data?.contract && (
          <>
            <div className="my-10" />
            <CreateAuctionForm tokenContract={data.contract} />
          </>
        )}

        {showPanel === "transfer" && publicAddress && !!data?.contract && (
          <>
            <div className="my-10" />
            <TransferFromForm
              publicAddress={publicAddress}
              tokenContract={data?.contract}
            />
          </>
        )}

        {showPanel === "burn" && publicAddress && !!data?.contract && (
          <>
            <div className="my-10" />
            <div className="text-bold">Coming soon!</div>
          </>
        )}

        {/* AUCTION OWNTER PANEL */}
        {auction && publicAddress === auctionOwner && (
          <AuctionOwnerPanel auction={auction} auctionState={auctionState} />
        )}

        {/* FIXME: need to check if the auction exists and is running */}
        {/* {auction && isAuctionRunning && ( */}
        {auction && (auctionState === 2 || auctionState === 3) && (
          <>
            {auctionState === 3 && (
              <div className="relative grid grid-cols-2">
                <div />
                <Countdown endDate={auctionStruct?.endTime.toString() * 1000} />
              </div>
            )}
            <div className="mt-10" />
            <AuctionPanel auction={auction} />
            <div className="mt-5" />
            <RecentBidsPanel auction={auction} />
          </>
        )}

        <div className="mt-10" />
        <TokenHistoryPanel token={data?.contract} auction={data?.auction} />
      </main>
      <div className="py-72" />
    </div>
  );
};
