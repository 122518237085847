import { ethers } from "ethers";

const ABI = ["function safeTransferFrom(address,address,uint256)"];

export const safeTransferFrom = async ({
  tokenAddress,
  from, //user publicAddress
  to, //auctionAddress
  id, //token id
}: {
  tokenAddress: string;
  from: string;
  to: string;
  id: string;
}) => {
  if (typeof window.ethereum !== "undefined") {
    // console.log(
    //   "Starting to Transfer Token...",
    //   tokenAddress,
    //   "safeTransferFrom(address,address,uint256)",
    //   [from, to, id]
    // );

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(tokenAddress, ABI, signer);
    console.log("Found contract", tokenAddress, contract);

    try {
      console.log("Starting safeTransferFrom Transaction");
      const transaction = await contract[
        "safeTransferFrom(address,address,uint256)"
      ](from, to, id);
      console.log("transaction", transaction);
      const result = await transaction.wait();
      console.log("transaction completed", result);
      return result;
    } catch (error) {
      console.error("safeTransferFrom Error:", error);
    }
  } else {
    console.error("Couldn't find window.ethereum");
  }
};
