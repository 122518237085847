export const AboutPage = () => {
  return (
    <main className="p-5 lg:p-0 mx-auto max-w-5xl min-h-screen">
      <header>
        <h1 className="text-5xl text-indigo-600 font-syne font-bold">About</h1>
      </header>
      <p className="text-xl font-space font-bold">
        More information coming soon!
      </p>
    </main>
  );
};
