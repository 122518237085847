import { ethers } from "ethers";
import { Link } from "react-router-dom";

import { Auction } from "../types/firestore";

export const AuctionCard = ({ auction }: { auction: Auction }) => {
  const reserve = ethers.utils.formatEther(auction.reserve);
  return (
    <Link
      className="block p-5 h-56 w-64 border border-b-2 space-y-2 text-center rounded shadow hover:shadow-2xl"
      to={`/tokens/${auction.tokenId}`}
    >
      <img
        className="mx-auto h-36 object-scale-down"
        src={auction?.tokenMetadata?.imageUri || "/penrose.svg"}
        alt=""
      />
      <h1 className="text-sm truncate font-bold">
        {auction?.tokenMetadata.name || " "}
      </h1>
      <h2 className="text-xs">Ξ {reserve}</h2>
    </Link>
  );
};

export const ContractCard = ({ contract }: { contract: any }) => {
  return (
    <Link
      className="block p-5 w-56 h-36 border border-b-2 space-y-2 text-center rounded shadow hover:shadow-2xl"
      to={`/contracts/${contract.id}`}
    >
      <img
        className="mx-auto h-14 object-scale-down"
        src={contract?.metadata?.imageUri || "/smart_contract.svg"}
        alt=""
      />
      <h1 className="text-sm truncate font-bold">
        {contract?.metadata?.name || " "}
      </h1>
      <h2 className="text-xs">{contract?.contractType.toUpperCase()}</h2>
    </Link>
  );
};

export const NFTCard = ({ token }: { token: any }) => {
  return (
    <Link
      className="block p-5 h-56 w-64 border border-b-2 space-y-2 text-center rounded shadow hover:shadow-2xl"
      to={`/tokens/${token.id}`}
    >
      <img
        className="mx-auto h-36 object-scale-down"
        src={token?.metadata?.imageUri || "/penrose.svg"}
        alt=""
      />
      <h1 className="text-sm truncate font-bold">
        {token?.metadata.name || " "}
      </h1>
    </Link>
  );
};
