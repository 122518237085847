import { useEffect, useState } from "react";

import { Auction, AuctionBid } from "../../../types/firestore";
import { unpackSnapshot } from "../../../utils/unpack_snapshot";
import { unpackSnapshotArray } from "../../../utils/unpack_snapshot_array";
import { queryClient } from "../../react_query/react_query";
import FirebaseClient from "../firebase_client";

export function useRecentBids({
  auction,
  limit,
}: {
  auction: Auction | undefined;
  limit?: number;
}) {
  const [bids, setBids] = useState<AuctionBid[]>([]);

  useEffect(() => {
    const close = FirebaseClient.db
      .collection("/bids")
      .where("auctionContract.id", "==", auction?.auctionContract.id)
      // .where("state", "in", ["processing", "completed"])
      .orderBy("updatedAt", "desc")
      // .limit(5)
      .onSnapshot(async (snapshots) => {
        //Update the react-query cache
        let newBids = unpackSnapshotArray({ snapshots }) as AuctionBid[];
        const cached = queryClient.getQueryData([
          "bids",
          { id: auction?.auctionContract.id },
        ]);
        if (!!limit) {
          newBids = newBids
            .sort((a, b) => b.createdAt - a.createdAt)
            .slice(0, limit);
        }
        // console.log("onSnapshot", cached, newBids);
        const promises = newBids.map((bid) => {
          // TODO: probably better to create a cloud function to combine avatars at the server (closest to the resources) than calls from client here
          //get avatar
          if (!bid?.byUserId) {
            return Promise.resolve(bid);
          }
          return FirebaseClient.db
            .collection("users")
            .doc(bid.byUserId)
            .get()
            .then((snapshot) => {
              if (!snapshot.exists) return bid;
              const data = unpackSnapshot({ snapshot });
              // console.log("data", data);

              return {
                ...bid,
                photoURL: data.photoURL,
              };
            });
        });

        newBids = await Promise.all(promises);

        queryClient.setQueryData(
          ["auctionBids", { id: auction?.auctionContract.id }],
          () => {
            const update = [...newBids].sort(
              (a, b) => b.createdAt - a.createdAt
            );
            // .filter((a) => a.state.includes("transaction"));
            setBids(update);
            return update;
          }
        );
      });

    return () => close();
  }, [auction, auction?.auctionContract]);

  return { bids };
}
