import { ethers } from "ethers";
import { Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { useMutation } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { useBaseContract } from "../../api/metamask/use_base_contract";
import { ContractContext } from "../../pages/contracts/ContractPage";
import { unpackSnapshot } from "../../utils/unpack_snapshot";
import { Spinner } from "../network_state/Spinner";

interface ContractFunctionInput {
  name: string;
  type: string;
  internalType: string;
}

interface ContractFunction {
  inputs: ContractFunctionInput[];
  name: string;
  output: any[];
  stateMutability: string;
  type: string; //'function' | 'event' | 'constructor'
  functionSelector: string;
}

function parseABI(abi: ContractFunction[]) {
  if (!abi) return [];
  // console.log(abi);
  const functions = abi
    ?.filter((m) => m.type === "function")
    .map((m) => {
      const inputs = m.inputs.map((i) => i.type);
      const functionSelector = `${m.name}(${inputs.join(",")})`;
      // console.log(functionSelector);
      return {
        ...m,
        functionSelector,
      };
    });
  // console.log("functions", functions);
  return functions as ContractFunction[];
}

export const ContractInterfacePanel = () => {
  const [result, setResult] = useState("");
  const data = useContext(ContractContext);
  // console.log("data", data);

  const { data: baseContract } = useBaseContract({ type: data?.contractType });
  // console.log("baseContract", baseContract);

  let functions = parseABI(baseContract?.abi);
  // FIXME:
  if (data?.contractType.includes("nft")) {
    functions = functions.filter((m) => !m.name.toLowerCase().includes("mint"));
  }
  // console.log(functions);

  // TODO: wrap this up in its own custom hook
  const mutation = useMutation(
    async (values: { selected: string; args: any[] }) => {
      if (!functions) throw new Error("Functions not found");
      if (typeof window.ethereum !== "undefined") {
        // Make a local copy so that we can change formatting of values without those same changes showing up in the Formik form
        const args = [...values.args];

        let contractType = data.contractType.toLowerCase();
        // console.log("ContractType", contractType);

        const snapshot = await FirebaseClient.db
          .collection("contracts")
          .doc(contractType)
          .get();
        const defaultContract = unpackSnapshot({ snapshot });
        console.log("default contract", defaultContract);

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          data.contract.address,
          defaultContract.abi,
          signer
        );
        // console.log("Found contract", contract);

        try {
          // console.log("Starting Transaction");
          const method = functions.find(
            (method: any) => method?.name === values.selected
          );
          if (!method) {
            throw new Error(`Function (${values.selected}) not found`);
          }

          const functionSelector = method.functionSelector;

          // If one of the Role functions, we need to transform the role string to a bytes32 string
          if (method.name.toLowerCase().includes("role") && args.length > 0) {
            //Role is always the first argument
            args[0] = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(args[0]));
          }

          // console.log("Calling", functionSelector);
          // If type view, other need to wait for the transaction to complete
          const transaction = await contract[functionSelector](...args);
          // console.log("transaction", transaction);

          if (method.stateMutability === "view") {
            setResult(transaction);
            return transaction;
          }
          // We only need to wait for transactions that produce events
          const result = await transaction.wait();
          console.log("Transaction Completed", result);
          // Parse emitted event and setResult
          const event = result.events.pop();
          console.log("Event", event);

          const keys = Object.keys(event.args).slice(event.args.length);
          console.log("Keys to use", keys);

          const formattedValues = keys.map((key) => {
            console.log("key", key, "arg", event.args[key]);
            return `${key}: ${event.args[key]}`;
          });

          console.log("FormatedValues", formattedValues);

          const formattedEvent = `${event.event}( ${formattedValues.join(
            ", "
          )} )`;
          setResult(formattedEvent);

          return result;
        } catch (error) {
          setResult(error);
          console.error("Error:", error);
          throw new Error(error);
        }
      } else {
        console.error("Couldn't find window.ethereum");
        throw new Error("Metamask not detected");
      }
    }
  );

  return (
    <div className="p-5 space-y-5 bg-white border rounded-lg shadow-2xl">
      <header>
        <h1 className="text-2xl font-bold">Contract Interface</h1>
        <p className="text-sm">
          Use the dropdown menu in this panel to select a contract function to
          call.
        </p>
      </header>

      <section className="space-y-2">
        <h2 className="text-xl font-medium border-b-2">General</h2>
        <Formik
          initialValues={{ selected: "", args: [] }}
          onSubmit={(values, actions) => {
            mutation.mutate(values);
          }}
        >
          {({ values, handleChange }) => {
            // console.log("values", values);

            const nInputs = functions.find(
              (method) => method.name === values.selected
            )?.inputs.length;
            return (
              <Form className="space-y-5">
                <div className="space-y-2">
                  <label
                    htmlFor="selected"
                    className="block text-sm font-bold text-gray-700"
                  >
                    Select Function
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      className="p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-lg sm:text-sm border-gray-300 rounded-md"
                      as="select"
                      name="selected"
                      selected={values.selected}
                      onChange={(e: any) => {
                        // On select change, we need to reset the args field and the result state
                        values.args = [];
                        setResult("");
                        handleChange(e); //turns control back to Formik
                      }}
                    >
                      <option value={""}></option>
                      {functions.map((method) => {
                        return (
                          <option
                            key={method.functionSelector}
                            value={method.name}
                          >
                            {method.name}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </div>

                <dl className="flex items-center align-middle space-x-5">
                  <dt className="text-sm font-bold">Function type</dt>
                  <dd className="text-sm">
                    {
                      functions.find(
                        (method) => method.name === values.selected
                      )?.stateMutability
                    }
                  </dd>
                </dl>

                {!!nInputs && (
                  <h2 className="text-xl font-bold">Function Inputs</h2>
                )}
                {functions
                  .find((method) => method.name === values.selected)
                  ?.inputs.map((arg: any, i: number) => {
                    return (
                      <div className="mt-3" key={arg.name}>
                        <label
                          htmlFor="auctionId"
                          className="block text-sm font-bold text-gray-700"
                        >
                          {arg.name}
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            name={`args.${i}`}
                            className="p-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder={arg.name}
                          />
                        </div>
                      </div>
                    );
                  })}

                {/* TODO: need to parse transaction emitted Events */}
                {/* Should be able to use the ABI to do this and not have to hardcode anything */}
                <div>
                  <header>
                    <h2 className="block text-xl font-bold text-gray-700">
                      Function Output
                    </h2>
                  </header>
                  <p className="p-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 w-full sm:text-sm border-gray-300 rounded-md">
                    {`${result}`}
                  </p>
                </div>

                <div className="mt-5" />
                <div className="flex justify-end space-x-5">
                  <button
                    className="inline-flex items-center bg-gray-400 hover:bg-gray-500 justify-center px-8 py-2  transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 text-white active:text-gray-800 rounded"
                    type="reset"
                  >
                    Cancel
                  </button>
                  <button
                    className="inline-flex items-center bg-indigo-600 hover:bg-indigo-700 justify-center px-8 py-2  transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 text-white active:text-gray-800 rounded"
                    type="submit"
                  >
                    {mutation.isLoading && <Spinner />}
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </section>
    </div>
  );
};
