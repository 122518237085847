import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export const CreateMenu = (props: any) => {
  // TODO: need to add the metamask connection info here
  return (
    <div className="flex z-20 items-center justify-center ">
      <div className="relative z-10 inline-block text-left">
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="inline-flex items-center bg-indigo-400 hover:bg-indigo-500 justify-center w-56 px-4 py-2  transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 text-white active:text-gray-800 rounded-xl">
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                <span>Create</span>
              </Menu.Button>
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute z-10 right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                >
                  <div className="p-1 space-y-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/contracts/deploy"
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-200"
                              : "text-gray-100"
                          } flex justify-between w-full px-4 py-2 bg-indigo-400 hover:bg-indigo-600 text-sm leading-5 text-left rounded`}
                        >
                          Deploy Contract
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/tokens/mint/nft`}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-200"
                              : "text-gray-100"
                          } flex justify-between w-full px-4 py-2 bg-indigo-400 hover:bg-indigo-600 text-sm leading-5 text-left rounded`}
                        >
                          Mint NFT
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/auctions/create"
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-200"
                              : "text-gray-100"
                          } flex justify-between w-full px-4 py-2 bg-indigo-400 hover:bg-indigo-600 text-sm leading-5 text-left rounded`}
                        >
                          Create Auction
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/tokens/mint/erc20`}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-200"
                              : "text-gray-100"
                          } flex justify-between w-full px-4 py-2 bg-indigo-200 hover:bg-indigo-300 text-sm leading-5 text-left rounded`}
                        >
                          Mint ERC20 Token
                        </Link>
                      )}
                    </Menu.Item>{" "}
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/tokens/mint/dao`}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-200"
                              : "text-gray-100"
                          } flex justify-between w-full px-4 py-2 bg-indigo-200 hover:bg-indigo-300 text-sm leading-5 text-left rounded`}
                        >
                          Mint DAO
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};
