import { ethers } from "ethers";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useMutation, useQuery } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { useMetamask } from "../../contexts/metamask.context";
import { unpackSnapshot } from "../../utils/unpack_snapshot";
import { Spinner } from "../network_state/Spinner";

interface TokenContract {
  address: string;
  id: string;
}

interface FormikValues {
  name: string;
  description: string;
  attributes: string[];
  reserve: string;
  tokenAddress: string;
  tokenId: string;
}

/**
 *
 */
export const CreateAuctionForm = ({
  tokenContract,
}: {
  tokenContract?: TokenContract;
}) => {
  // console.log(`Received TokenContract: ${tokenContract?.address}`);

  const { publicAddress } = useMetamask();

  const { data: selectedAuctionContract } = useQuery(
    // TODO: update the key with an Id once I have multiple auction contracts
    ["defaultAuctionContract", "default"],
    async () => {
      return FirebaseClient.db
        .collection("contracts")
        .doc("auction")
        .get()
        .then((snapshot) => {
          return unpackSnapshot({ snapshot });
        });
    }
  );

  const mutation = useMutation(async (values: FormikValues) => {
    if (typeof window.ethereum !== "undefined") {
      // console.log("Starting to create an auction...", values);
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const auctionContract = new ethers.Contract(
          selectedAuctionContract?.contract.address,
          selectedAuctionContract.abi,
          signer
        );
        // console.log("Found auction contract", auctionContract.address, signer);

        // Step 1. We create an AuctionRequest in our database (firestore)
        const { data: response } = await FirebaseClient.functions.httpsCallable(
          "createAuction"
        )({
          auctionContract: {
            address: selectedAuctionContract?.contract.address,
          },
          auctionMetadata: {
            name: values.name,
            description: values.description,
            attributes: values.attributes,
          },
          reserve: values.reserve,
          tokenContract: { address: values.tokenAddress, id: values.tokenId },
          requestedByAddress: publicAddress,
        });
        // console.log("Firebase Response", response);

        // Step 2. User creates the auction
        // TODO: at this point we can charge the user by including a minimum value that they must send as part of the transaction
        // console.log("Starting create auction transaction", values.tokenAddress);

        const transaction = await auctionContract[
          "createAuction(string,address,uint256,uint256,uint256)"
        ](
          values.name,
          values.tokenAddress,
          ethers.BigNumber.from(values.tokenId),
          ethers.BigNumber.from(values.reserve),
          response.auction.auctionContract.id
        );
        // console.log("transaction", transaction);

        // TODO: can add in auctionContract.id to the lookup mix
        FirebaseClient.functions.httpsCallable("transactionRequested")({
          contract: { address: selectedAuctionContract?.contract.address },
          firestore: { collection: "auctions", id: response.documentId },
          requestedByAddress: publicAddress,
        });

        const result = await transaction.wait();
        // console.log("transaction completed", result);
        return result;
      } catch (error) {
        console.error("CreateAuction Error:", error);
      }
    } else {
      console.error("couldn't find window.ethereum");
    }
  }, {});

  return (
    <div className="relative bg-white border rounded-lg shadow-2xl overflow-hidden">
      <header className="px-8 pt-8 space-y-2">
        <h1 className="text-2xl font-semibold" style={{ lineHeight: "120%" }}>
          Auction details
        </h1>
        <p className="text-sm text-gray-500">
          This form allows you to create a new standard
          VertuaLabsPermissiveAuction.
        </p>
      </header>

      <div className="mt-5" />

      <Formik
        initialValues={
          {
            name: "",
            description: "",
            attributes: [],
            reserve: "0.1",
            tokenAddress: tokenContract?.address || "",
            tokenId: tokenContract?.id.toString() || "",
          } as FormikValues
        }
        onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
          mutation.mutate({
            ...values,
            reserve: ethers.utils.parseEther(`${values.reserve}`).toString(),
          });
        }}
      >
        <Form className="">
          {/* CONTRACT ADDRESS */}
          <div className="px-8 pb-8 space-y-5">
            <div className="">
              <label
                htmlFor="tokenAddress"
                className="block text-sm font-bold text-gray-700"
              >
                Token Address
              </label>
              <div className="mt-1">
                <Field
                  type="text"
                  name="tokenAddress"
                  id="tokenAddress"
                  className="field-text"
                  placeholder="Token Address"
                />
              </div>
            </div>

            {/* CONTRACT ID*/}
            <div className="">
              <label
                htmlFor="tokenId"
                className="block text-sm font-bold text-gray-700"
              >
                Token Id
              </label>
              <div className="mt-1">
                <Field
                  type="text"
                  name="tokenId"
                  id="tokenId"
                  className="field-text"
                  placeholder="Token Id"
                />
              </div>
            </div>

            {/* RESERVE */}
            <div className="">
              <label
                htmlFor="attributes"
                className="block text-sm font-bold text-gray-700"
              >
                Reserve (ETH)
              </label>
              <div className="mt-1">
                <Field
                  type="number"
                  name="reserve"
                  id="reserve"
                  className="field-text"
                  placeholder="0.1 ETH"
                />
              </div>
            </div>
          </div>
          <div className="px-8 py-2 flex justify-end space-x-5 bg-gray-50 mt-2">
            <button type="reset" className="btn-reset">
              <span>Reset</span>
            </button>
            <button type="submit" className="btn-submit">
              {mutation.isLoading && <Spinner />}
              <span>Create Auction</span>
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
