import { createContext, useContext, useEffect, useState } from "react";

import FirebaseClient from "../api/firebase/firebase_client";

export const FirebaseAuthContext = createContext(
  FirebaseClient.auth?.currentUser
);

// TODO: a single AuthProvider that integrates metamask and firebase
export const FirebaseAuthProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState(FirebaseClient.auth.currentUser);

  useEffect(() => {
    return FirebaseClient.auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("The user is logged in");
        setUser(user);
      } else {
        setUser(null);
        console.log("The user is not logged in", user);
      }
    });
  }, []);

  return (
    <FirebaseAuthContext.Provider value={user}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export const useFirebaseUser = () => useContext(FirebaseAuthContext);
