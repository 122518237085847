import { Helmet } from "react-helmet";

import { useOnSnapshotWhereEqual } from "../api/firebase/use_on_snapshot_where_equal";
import { ContractCard, NFTCard } from "../components/BasicCards";
import { useMetamask } from "../contexts/metamask.context";

export const Dashboard = () => {
  const { accounts } = useMetamask();
  const publicAddress = accounts[0];
  // console.log("Public Address", publicAddress);

  const { data: tokens } = useOnSnapshotWhereEqual(
    "tokens",
    "ownedByAddress",
    publicAddress || ""
  );

  const { data: auctions } = useOnSnapshotWhereEqual(
    "auctions",
    "ownedByAddress",
    publicAddress || ""
  );

  const { data: contracts } = useOnSnapshotWhereEqual(
    "contracts",
    "ownedByAddress",
    publicAddress || ""
  ) as any;

  // TODO: see if token is actively being auctioned

  return (
    <main className="p-5 lg:p-0 mx-auto max-w-5xl">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <header>
        <h1>Your Dashboard</h1>
      </header>

      <div className="py-10" />
      <section>
        <h1 className="text-3xl font-bold leading-loose">Contracts</h1>
        <ul className="grid grid-col-1 sm:grid-cols-2 lg:grid-cols-4 place-items-center gap-8">
          {(contracts as any)?.slice(0, 8).map((contract: any) => {
            return <ContractCard key={contract.id} contract={contract} />;
          })}
        </ul>
      </section>

      <div className="py-10" />
      <section>
        <h1 className="text-3xl font-bold leading-loose">Tokens</h1>
        <ul className="grid grid-col-1 sm:grid-cols-2 lg:grid-cols-4 place-items-center gap-8">
          {(tokens as any)?.slice(0, 8).map((token: any) => {
            return <NFTCard key={token.id} token={token} />;
          })}
        </ul>
      </section>

      <div className="py-96" />
    </main>
  );
};

export default Dashboard;
