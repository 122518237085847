import { useEffect, useState } from "react";

const calculateTimeLeft = (endDate: string | number) => {
  const difference = +new Date(endDate) - +new Date();

  const timeLeft = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };
  return timeLeft;
};

export const Countdown = ({ endDate }: { endDate: string | number }) => {
  // console.log("endDate", endDate);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <dl className="px-8 py-2 bg-white grid grid-cols-4 rounded-lg shadow-2xl">
      {["days", "hours", "minutes", "seconds"].map((t: string) => (
        <div key={t} className="">
          {/* @ts-ignore */}
          <dd className="text-xl text-indigo-600 font-mono font-extrabold">
            {/* @ts-ignore */}
            {timeLeft[t]}
          </dd>
          <dt className="text-xs">{t}</dt>
        </div>
      ))}
    </dl>
  );
};
