import { XCircleIcon } from "@heroicons/react/solid";
import { useMutation } from "react-query";

import { cancelAuction } from "../../api/metamask/auctions/cancel_auction";
import { withdrawToken } from "../../api/metamask/auctions/withdraw_token";
import { useMetamask } from "../../contexts/metamask.context";
import { Auction } from "../../types/firestore";
import { Spinner } from "../network_state/Spinner";
import { AuctionCheckList } from "./AuctionCheckList";

const parseAuctionState = (n: number | undefined): string => {
  // console.log("typeof", typeof n);
  switch (n) {
    case 0:
      return "CREATED";
    case 1:
      return "READY";
    case 2:
      return "RUNNING";
    case 3:
      return "COUNTINGDOWN";
    case 4:
      return "ENDED";
    case 5:
      return "CANCELLED";
    default:
      return "UNDEFINED";
  }
};

export const AuctionOwnerPanel = ({
  auction,
  auctionState,
}: {
  auction: Auction | undefined;
  auctionState: any;
}) => {
  const { publicAddress } = useMetamask();
  const cancelAuctionMutation = useMutation(async () => {
    // if (!auction) return;
    // console.log("running");

    return cancelAuction({
      address: auction?.auctionContract.address,
      id: auction?.auctionContract.id,
    });
  });

  const withdrawTokenMutation = useMutation(async () => {
    if (!auction) return;
    return withdrawToken({
      address: auction?.auctionContract.address,
      id: auction?.auctionContract.id,
    });
  });

  const auctionIsRunning = auctionState == 2 || auctionState == 3;

  const canWithdrawToken =
    !auctionIsRunning && auction?.ownedByAddress !== publicAddress;

  return (
    <section className="p8 brder shdow runded">
      {/* If being Auctioned, display AuctionPanel */}
      {/* If not being Auctioned and owner if viewing, display Actions Panel */}

      {/* <header>
        <h2 className="text-xl font-bold">Auction Owner Actions</h2>
        <div className="pt-2" />

        <dl className="grid grid-cols-1 sm:grid-cols-12 items-center place-items-left grid-flow-row-dense gap-1">
          <dt className="sm:col-span-2 text-xs">Auction State</dt>
          <dt className="sm:col-span-2 text-sm font-bold">
            {auctionState} = {parseAuctionState(auctionState)}
          </dt>
        </dl>
      </header> */}
      {/* <div className="pt-4" /> */}

      {/* If the Auction is running, show the Cancel Button */}
      {auctionIsRunning && (
        <button
          type="button"
          className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-400 shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
          onClick={() => cancelAuctionMutation.mutate()}
        >
          <XCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          <span>Cancel Auction</span>
        </button>
      )}

      {/* If the Auction is not running and the auctioned Token has been transferred to the Auction, show option to return it to the seller/user 
      TODO: finish this pattern. need to check to make sure auction hasn't eneded and that the token should be transferred to the winner. So. Should only allow returning token for states CREATED, READY, and CANCELLED
      */}
      {canWithdrawToken && (
        <button
          type="button"
          className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
          onClick={() => withdrawTokenMutation.mutate()}
        >
          {withdrawTokenMutation.isLoading && <Spinner />}
          <XCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          <span>Return Token</span>
        </button>
      )}

      {/* Show the Auction Checklist if the Auction has not yet started. */}
      {(auctionState === 0 || auctionState === 1) && (
        <>
          {/* <div className="my-10" /> */}
          <AuctionCheckList
            auctionContract={auction?.auctionContract}
            tokenContract={auction?.tokenContract}
          />
        </>
      )}
    </section>
  );
};
