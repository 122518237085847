import { ethers } from "ethers";
import { useContext } from "react";
import { Helmet } from "react-helmet";

import { useERC20MinterView } from "../../api/metamask/use_contract_query";
import { ContractHeader } from "../../components/contracts/ContractHeader";
import { ContractInterfacePanel } from "../../components/contracts/ContractInterface";
import { useMetamask } from "../../contexts/metamask.context";
import { ContractContext } from "./ContractPage";

/**
 *
 *
 */
export const ERC20MinterContract = () => {
  const data = useContext(ContractContext);
  // console.log("Data", data);

  const { publicAddress } = useMetamask();

  let { data: userTokens } = useERC20MinterView(
    data?.contract.address,
    [publicAddress],
    "balanceOf(address)"
  );
  userTokens = userTokens && ethers.utils.commify(userTokens);

  const { data: contractOwner } = useERC20MinterView(
    data?.contract.address,
    [],
    "owner()"
  );
  const { data: contractName } = useERC20MinterView(
    data?.contract.address,
    [],
    "name()"
  );
  const { data: contractSymbol } = useERC20MinterView(
    data?.contract.address,
    [],
    "symbol()"
  );
  let { data: totalSupply } = useERC20MinterView(
    data?.contract.address,
    [],
    "totalSupply()"
  );
  totalSupply = totalSupply && ethers.utils.commify(totalSupply);

  const header = `${contractName || data?.metadata?.name} (${
    data?.metadata?.symbol || contractSymbol
  })`;

  const subheader = "ERC-20 Contract";

  return (
    <div className="p-5 lg:p-0 min-h-screen mx-auto max-w-7xl">
      <Helmet>
        <header>{header} Contract Page</header>
      </Helmet>

      <ContractHeader contract={data} header={header} subheader={subheader} />

      <div className="py-10" />

      <main>
        <section className="p-5 bg-white border rounded-lg shadow-2xl">
          <header>
            <h1 className="text-xl font-bold">Token Supply Details</h1>
          </header>
          <div className="mt-5" />
          <dl className="grid grid-cols-5 align-middle">
            <dt className="text-xs font-bold">You Own</dt>
            <dd className="col-span-4 text-sm truncate">
              {userTokens || "unknown"}
            </dd>
            <dt className="text-xs font-bold">Total Supply</dt>
            <dd className="col-span-4 text-sm truncate">
              {totalSupply || data?.totalSupply || "unknown"}
            </dd>
          </dl>
        </section>

        <div className="py-5" />
        <ContractInterfacePanel />
      </main>
    </div>
  );
};
